import React, 
{
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  FocusedWorkContainer, 
  ParentContainer, 
  PromptOptionsContainer, 
  CircularLoadingContainer,
  OptionSelectionContainer,
  ArtGenButtonsContainer
} from './styled.js';
import { 
  TextField, 
  Button,
  Paper,
  CircularProgress,
  Fade,
  Tabs,
  Tab,
  useMediaQuery,
  Dialog,
  IconButton,
  DialogContent,
} from '@mui/material';
import { 
  colorOptions, 
  lightingOptions, 
  styleOptions 
} from '../../staticData/artworkOptions.js';
import { artGeneratorDispatcher, submitUpscaleJob } from '../../actions/artworkProcessing.js';
import { artworkURL1 } from '../../staticData/artworkurl.js';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import StyleMenu from './StyleMenu.js';
import { saveArtworkInUserProfile, subtractCreditsFromUser } from '../../actions/userActions.js';
import { storeImagesInCloudinary } from '../../helpers/dbImageSave.js';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { useTheme } from '@emotion/react';
import { useToast } from '../../libs/toast';
import { upscaleImage } from '../../helpers/upscaleImage.js';

function CreateArtwork() {

  const userId               = useSelector(
    (state) => state?.userData?.loginInfo?.user_id
  );
  const artworkGenerationURL = useSelector(
    (state) => state?.stepData?.currentInfo?.artworkProps?.base64Image
  );
  const artworkGenerating = useSelector(
    (state) => state?.stepData?.currentInfo?.artworkProps?.isLoading
  );
  const artworkSaveDispatch  = useSelector(
    (state) => state?.userData?.artworkSaving
  );

  const userInfo = useSelector((state) => state?.userData?.loginInfo);

  const dispatch        = useDispatch()                                   ;
  const isMediumScreen  = useMediaQuery('(max-width: 960px)')             ;
  const theme           = useTheme()                                      ;
  const isMobile        = window.matchMedia('(max-width: 768px)').matches ;
  const navigate        = useNavigate()                                   ;
  const toast           = useToast()                                      ;

  const [artBasePrompt, setArtBasePrompt]           = useState('');
  const [optionsExpanded, setOptionsExpanded]       = useState(0);
  const [artworkSaveLoading, setArtworkSaveLoading] = useState(false);
  const [openDialogue, setOpenDialogue]             = useState(false);
  const [artworkData, setArtworkData]               = useState({
    colors: [],
    styles: [],
    lightings: [],
    base64Image: artworkGenerationURL,
    resolution: '1024x1024',
    prompt: artBasePrompt,
    n: 1
  });

  // TODO Customize Prompt with selected artwork data
  const optionsToCompletePrompt = () => {
    const colorsString = artworkData.colors.map(
      str => str.toUpperCase()
    ).join(', ');

    const stylesString = artworkData.styles.map(
      str => str.toUpperCase()
    ).join(', ');

    const lightingsString = artworkData.lightings.map(
      str => str.toUpperCase()
    ).join(', ');

    const completePrompt = `${artBasePrompt}, featuring a palette of ${colorsString}, rendered in the artistic tradition of ${stylesString}, under ${lightingsString} conditions`

    return completePrompt;
  };

  const createCloudinaryUrl = async() => {
    return await storeImagesInCloudinary(artworkData?.base64Image, userInfo?.user_id)
    .then((res) => { return res?.data });
  };  

  const generateArtwork = () => {

    dispatch(artGeneratorDispatcher({
      "prompt": optionsToCompletePrompt(),
      "n"     : artworkData.n            ,
      "size"  : artworkData.resolution
    }, dispatch ));

    dispatch(subtractCreditsFromUser(userId, dispatch));
  };


  const saveImage = async() => {
    setArtworkSaveLoading(true);
    // Get cloudinary URL
    const hostedBaseImageUrl = await createCloudinaryUrl();

    const upscaleJobSubmitResponse = await dispatch(
      submitUpscaleJob(
        hostedBaseImageUrl,
        dispatch
      )
    );

    if(upscaleJobSubmitResponse?.status === 400){
      toast.error('There was an error upscaling image');
      console.error('Error: ', upscaleJobSubmitResponse?.error);
      return;
    };

    dispatch(
      saveArtworkInUserProfile(
        {
          colors: artworkData?.colors,
          styles: artworkData?.styles,
          lightings: artworkData?.lightings,
          prompt: artworkData?.prompt,
          hostedBaseImageUrl: hostedBaseImageUrl,
          upscaleJobId: upscaleJobSubmitResponse?.tid,
          upscaleProcessing: true
        },
        userId,
        dispatch
      )
    )
    .then((response) => {
      if(response.status === 200){
        toast.success('Artwork Saved In Profile!');
        upscaleImage(userInfo, response.data, dispatch);
      } else {
        toast.error(response);
      }
    });

    setArtworkData((prev) => ({
      ...prev,
      colors: [],
      styles: [],
      lightings: [],
      prompt: ''
    }));
  };

  const addMoreCredits = () => {
    navigate('/profile/info');
  };

  const handleTabChange = (event, newValue) => {
    setOptionsExpanded(newValue);
  };

  const handleOptionChange = (value, type) => {
    setArtworkData((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const updateOptions = (updatedValue, type) => {

    if(artworkData[type].includes(updatedValue)){
      setArtworkData({
        ...artworkData, 
        [type]: artworkData[type].filter(
          (value) => value !== updatedValue
        )
      });
    } else {
      setArtworkData({
        ...artworkData,
        [type]: [...artworkData[type], updatedValue]
      })
    };
  };
  
  useMemo(() => {
    setArtworkSaveLoading(artworkSaveDispatch);
  }, [artworkSaveDispatch]);

  useEffect(() => {
    setArtworkData({
      ...artworkData, 
      prompt: artBasePrompt
    });

  // eslint-disable-next-line
  }, [artBasePrompt]);

  useEffect(() => {

    if(artworkGenerationURL){
      setArtworkData({
        ...artworkData, 
        base64Image: artworkGenerationURL ?? artworkURL1
      });
    }

  // eslint-disable-next-line
  }, [artworkGenerationURL]);

  const artworkDialogDisplay = () => (
    <Dialog
      onClose={() => setOpenDialogue(false)}
      aria-labelledby="customized-dialog-title"
      open={openDialogue}
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpenDialogue(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          backgroundColor: 'gray',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <img 
          src={artworkGenerationURL} 
          alt="Generation" 
          style={{ maxWidth: '100%', maxHeight: '100%'}}
          onClick={() => setOpenDialogue(true)}
        />
      </DialogContent>
    </Dialog>
  );
  
  const handleGenImageContainerDisplay = () => {
    if(artworkGenerating){
      return (
        <FocusedWorkContainer item>
          <CircularLoadingContainer>
            <CircularProgress color='secondary' size={'10rem'}/>
          </CircularLoadingContainer>
        </FocusedWorkContainer>
      )
    }

    if(artworkGenerationURL){
      return (
        <Fade in>
          <FocusedWorkContainer item>
            <img 
              src={artworkGenerationURL} 
              alt="Generation" 
              style={{ maxWidth: '100%', maxHeight: '15rem', padding: !isMobile ? '1rem' : '0', position: 'relative'}}
            />
            <IconButton
              sx={{ 
                position: 'absolute', 
                bottom: 0, 
                right: '15%', 
                zIndex: 20,
                border: '1px solid lightgrey',
                backgroundColor: 'white'
              }}
              onClick={() => setOpenDialogue(true)}
            >
              <ZoomInIcon size='md' />
            </IconButton>
          </FocusedWorkContainer>
        </Fade>
      )
    }

    return (
      <Fade in>
        <FocusedWorkContainer item>
          <Paper
            elevation={0}
            sx={{
              width: '75%',
              margin: isMobile ? '0' : '1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '1rem 0',
              backgroundColor: 'transparent'
            }}
          >
            <CenterFocusWeakIcon sx={{fontSize: '10rem', color: '#d7d7d7'}} />
          </Paper>
        </FocusedWorkContainer>
      </Fade>
    )
  }
  
  return (
    <ParentContainer container>
      { 
        artworkDialogDisplay() 
      }
      <TextField 
        placeholder = 'Enter prompt for art generator here...'
        variant     = "outlined" 
        color       = 'secondary'
        sx          = {{ width: isMediumScreen ? '90%' : '75%'}}
        onChange    = {(e) => setArtBasePrompt(e.target.value)}
      />
        
      { 
        handleGenImageContainerDisplay() 
      }

      <PromptOptionsContainer item>
        <OptionSelectionContainer>
          <Tabs
            value           = { optionsExpanded }
            onChange        = { handleTabChange }
            indicatorColor  = 'secondary'
            textColor       = 'secondary'
            centered
          >
            <Tab label  = "Style"    sx={{fontFamily: `${theme.typography.secondary.fontFamily}`}} />
            <Tab label  = "Color"    sx={{fontFamily: `${theme.typography.secondary.fontFamily}`}} />
            <Tab label  = "Lighting" sx={{fontFamily: `${theme.typography.secondary.fontFamily}`}} />
          </Tabs>
          <StyleMenu 
            optionType          = "styles"
            optionMenu          = { 0                     } 
            options             = { styleOptions          }
            updateOptions       = { updateOptions         }
            handleOptionChange  = { handleOptionChange    }
            optionsExpanded     = { optionsExpanded       }
            addMoreCredits      = { addMoreCredits        }
            selectedOptions     = { artworkData?.styles   }
            creditBalance       = { userInfo?.userCredits }
          />
          <StyleMenu 
            optionType          = "colors"
            optionMenu          = { 1                     } 
            options             = { colorOptions          }
            updateOptions       = { updateOptions         }
            handleOptionChange  = { handleOptionChange    }
            optionsExpanded     = { optionsExpanded       }
            addMoreCredits      = { addMoreCredits        }
            selectedOptions     = { artworkData?.colors   }
            creditBalance       = { userInfo?.userCredits }
          />
          <StyleMenu 
            optionType          = "lightings"
            optionMenu          = { 2                       } 
            options             = { lightingOptions         }
            updateOptions       = { updateOptions           }
            handleOptionChange  = { handleOptionChange      }
            optionsExpanded     = { optionsExpanded         }
            addMoreCredits      = { addMoreCredits          }
            selectedOptions     = { artworkData?.lightings  }
            creditBalance       = { userInfo?.userCredits   }
          />
        </OptionSelectionContainer>
        <ArtGenButtonsContainer>
          <Button 
            variant   = 'contained'
            color     = 'secondary'
            sx        = {{ fontFamily: `${theme.typography.secondary.fontFamily}`, marginRight: '1rem' }}
            onClick   = {() => generateArtwork()}
            disabled  = {
              !artBasePrompt    || 
              artworkGenerating || 
              artworkSaveLoading 
            }
          >
            Run Art Generator
          </Button>
          <Button 
            variant   = 'contained' 
            color     = 'secondary'
            sx        = {{fontFamily: `${theme.typography.secondary.fontFamily}`}}
            onClick   = {() => saveImage()}
            disabled  = {
              !artworkGenerationURL || 
              artworkSaveLoading
            }
          >
            Save In Profile
          </Button>
        </ArtGenButtonsContainer>
      </PromptOptionsContainer>
    </ParentContainer>
  )
}

export default CreateArtwork;