export const GET_PRINTFUL_PRODUCT_REQUEST = 'GET_PRINTFUL_PRODUCT_REQUEST';
export const GET_PRINTFUL_PRODUCT_SUCCESS = 'GET_PRINTFUL_PRODUCT_SUCCESS';
export const GET_PRINTFUL_PRODUCT_FAIL    = 'GET_PRINTFUL_PRODUCT_FAIL'   ;

export const RETRIEVE_TEMPLATE_DATA_REQUEST = 'RETRIEVE_TEMPLATE_DATA_REQUEST';
export const RETRIEVE_TEMPLATE_DATA_SUCCESS = 'RETRIEVE_TEMPLATE_DATA_SUCCESS';
export const RETRIEVE_TEMPLATE_DATA_FAIL    = 'RETRIEVE_TEMPLATE_DATA_FAIL'   ;

export const SAVE_ARTWORK_PLACEMENT_REQUEST = 'SAVE_ARTWORK_PLACEMENT_REQUEST';
export const SAVE_ARTWORK_PLACEMENT_SUCCESS = 'SAVE_ARTWORK_PLACEMENT_SUCCESS';
export const SAVE_ARTWORK_PLACEMENT_FAIL    = 'SAVE_ARTWORK_PLACEMENT_FAIL'   ;

export const ORDER_STATUS_UPDATE_REQUEST = 'ORDER_STATUS_UPDATE_REQUEST';
export const ORDER_STATUS_UPDATE_SUCCESS = 'ORDER_STATUS_UPDATE_SUCCESS';
export const ORDER_STATUS_UPDATE_FAIL = 'ORDER_STATUS_UPDATE_FAIL';