import React, { useState } from 'react';
import { 
  Button, 
  CardActionArea, 
  CardActions, 
  CardContent, 
  CardMedia, 
  Container, 
  Fade, 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select,
  Typography, 
} from '@mui/material';
import { 
  PrimaryTypography, 
  ProductCardContainer, 
  ProductCardContent, 
  SecondaryTypography
} from './styled';
import { productPriceUpcharge } from '../../staticData/products';

function ProductCard({
  productSelectionCallback  ,
  quantityChange            ,
  selectedProducts          ,
  productInfo               ,
  variantInfo               ,
  handleUpdateProductVariant,
  ...rest
}) {
  
  const [variant, setVariant] = useState(variantInfo[0]);
  const isolateProductInCurrentlySelected = () => {
    const isolatedProductInArray = selectedProducts?.filter(
      product => product?.id === productInfo?.id
    );

    return isolatedProductInArray[0] ?? '';
  };

  const quantity = isolateProductInCurrentlySelected()?.quantity;
  const cartId   = isolateProductInCurrentlySelected()?.cartId  ;

  const handleIncrement = () => {
    quantityChange(cartId, quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      quantityChange(cartId, quantity - 1);
    }
  };

  const updateProductVariant = (variantInfo) => {
    setVariant(variantInfo);
    handleUpdateProductVariant(cartId, variantInfo);
  };

  const setPrice = (variantPrice) => {
    const initialPriceInCents = parseFloat(variantPrice) * 100;
    const finalPrice = initialPriceInCents * productPriceUpcharge;
    const removeDecimals = finalPrice?.toFixed(0);
        
    return removeDecimals;
  };

  return (
    <Fade in timeout={1000}>
      <ProductCardContainer>
        <CardActionArea disableRipple>
          <CardMedia
            component="img"
            height="100%"
            image={productInfo?.image}
            alt="green iguana"
          />
          <ProductCardContent>
            <PrimaryTypography
              gutterBottom 
              variant="h5" 
              component="div"
            >
              {productInfo?.title}
            </PrimaryTypography>
            <Typography
              gutterBottom 
              variant="body1" 
              component="div"
            >
              Price: ${(parseFloat(variant?.price) * productPriceUpcharge).toFixed(2)}
            </Typography>
          </ProductCardContent>
          <CardActions sx={{width: '100%', margin: '0'}}>
            { (!quantity || quantity < 1) ?  (
              <CardContent
                sx={{
                  display: 'flex', 
                  alignItems: 'center',
                  width: '100%',
                  flex: '1',
                }}
              >
                <Button
                  color   = 'secondary'
                  variant = 'contained'
                  disableRipple
                  sx      = {{
                    width: '100%', 
                    color: 'primary',
                  }} 
                  onClick = {() => {
                    productSelectionCallback(
                      productInfo?.id                              , 
                      productInfo?.title                           , 
                      1                                            ,
                      productInfo?.main_category_id                ,
                      variant                                      ,
                      setPrice(variant?.price),
                      {...rest}
                    )
                  }}
                >
                  <SecondaryTypography>
                    Select
                  </SecondaryTypography>
                </Button> 
              </CardContent>
              ) : (
              <Container sx={{padding: '0!important'}}>

                <CardContent
                  sx={{
                    display         : 'flex'         , 
                    flex            : '1'            ,
                    justifyContent  : 'space-between',
                    alignItems      : 'center'       ,
                    width           : '100%'
                  }}
                >
                  <Button 
                    variant = "contained" 
                    color   = "secondary" 
                    onClick = {handleDecrement}
                    sx      = {{minWidth: '3rem'}}
                  >
                    <SecondaryTypography>
                      -
                    </SecondaryTypography>
                  </Button>
                  <SecondaryTypography variant="h5">
                    {quantity}
                  </SecondaryTypography>
                  <Button 
                    variant = "contained" 
                    color   = "secondary" 
                    onClick = {handleIncrement}
                    sx      = {{minWidth: '3rem'}}
                  >
                    <SecondaryTypography>
                      +
                    </SecondaryTypography>
                  </Button>
                </CardContent>
                <CardContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                  <FormControl fullWidth>
                    <InputLabel id="variant">Variant</InputLabel>
                    <Select
                      labelId   = "variant"
                      id        = "variant"
                      value     = {variant}
                      label     = "Variant"
                      onChange  = {(e) => updateProductVariant(e.target.value)}
                    >
                      {variantInfo.map((variant) => (
                        <MenuItem value={variant}>
                          <SecondaryTypography>
                            {variant?.name}
                          </SecondaryTypography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Container>
              )
            }
          </CardActions>
        </CardActionArea>
      </ProductCardContainer>
    </Fade>
  )
}

export default ProductCard;