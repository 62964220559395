import React, { useMemo, useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  CircularProgress,
  Fade,
  Radio,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  CircularLoadingContainer,
  OptionAccordion, 
  OptionAccordionDetails, 
  OptionAccordionSummary,
  DetailsProductGridContainer, 
  ImageThumbnailContainer, 
  ProductOrderedCard, 
  CheckoutNavigationButtonContainer,
  CardGridContainer
} from './styled';
import { 
  saveOrderInfo
} from '../../actions/orderPlacementActions';
import {
  saveShippingInfo,
  processPayment
} from '../../actions/userActions';
import ShippingModule from './ShippingModule';
import { storeImagesInCloudinary } from '../../helpers/dbImageSave';
import { useToast } from '../../libs/toast';
import PaymentStripe from './PaymentStripe';
import { 
  AddInfoButton, 
  AddInfoCard, 
  CardImageGridContainer, 
  CardInfoTypography, 
  InfoGrid, 
  InformationCard, 
  SecondaryTypography 
} from '../UserProfile/styled';
import { AddBox } from '@mui/icons-material';
import { showCreditCardImage } from './helpers';
import { placePrintfulOrder } from '../../actions/printfulActions';
import { calculateOrderCostTotals } from '../../actions/cartActions';

function PaymentAndShipping(){

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast    = useToast()   ;

  const userStripePaymentMethods = useSelector(
    (state) => state?.stripeData?.userStripePaymentMethods
  );
  const userInfo             = useSelector((state) => state?.userData?.loginInfo)                     ;
  const paymentSubmitting    = useSelector((state) => state?.userData?.paymentInfo?.submittingPayment);
  const cartItems            = useSelector((state) => state.cartInfo.cartItems)                       ;
  const orderSubmitting      = useSelector((state) => state?.orderData?.orderSubmitting)              ;
  const orderCostTotals      = useSelector((state) => state?.cartInfo?.orderCostTotals);
  
  const [optionsExpanded, setOptionsExpanded]         = useState(0)     ;
  const [changeShipping, setChangeShipping]           = useState(false) ;
  const [paymentView, setPaymentView]                 = useState('List');
  const [selectedPayment, setSelectedPayment]         = useState(null)  ;
  const [orderTotalPrice, setOrderTotalPrice]         = useState(null)  ;
  const [orderInfo, setOrderInfo]                     = useState(null)  ;
  const [itemsRetailPrice, setItemsRetailPrice]       = useState(null)  ;

  const [shippingData, setShippingData] = useState({
    firstName   : null             ,
    lastName    : null             ,
    addressOne  : null             ,
    addressTwo  : null             ,
    city        : null             ,
    stateName   : null             ,
    stateCode   : null             ,
    zipCode     : null             ,
    countryName : 'United States',
    country     : 'US'
  });

  const handleShippingDataChange = (fieldToChange, newData) => {
    setShippingData((prev) => ({
      ...prev,
      [fieldToChange]: newData
    }));
  };

  const saveShippingData = () => {
    dispatch(
      saveShippingInfo(
        shippingData    , 
        userInfo.user_id, 
        dispatch
      )
    ).then((response) => {
      if(response === 200){
        toast.success('Shipping Method Saved!');
      } else {
        toast.error(response);
      };
    });

    setChangeShipping(false);
  };

  const calculateTotalRetailPrice = () => {
    const shippingPrice = orderCostTotals?.totals?.shipping * 100;
    const taxPrice = orderCostTotals?.totals?.tax * 100;
    const orderTotal = shippingPrice + taxPrice + itemsRetailPrice;
    
    setOrderTotalPrice(orderTotal.toFixed(0))
  };

  const submitPaymentDispatcher = async() => {

    dispatch(
      processPayment({
        totalPrice: parseInt(orderTotalPrice),
        currency: 'usd',
        selectedPayment: selectedPayment,
        description: 'Customer Order',
      }, 
      dispatch
    )).then(async(response) => {
      console.log('submit payment response', response);

      if(response === 200){
        toast.success('Payment Processed!');
        
        dispatch(
          placePrintfulOrder({
            orderItems: orderInfo?.orderItems, 
            customerData: userInfo, 
            shippingData: shippingData
          }, dispatch)
        )
        .then((printfulOrderResponse) => {
          if(printfulOrderResponse?.status === 200){

            const orderDataPostPayment = {
              ...orderInfo,
              payment         : response?.id                      ,
              paymentResult   : 'fulfilled'                       ,
              isPaid          : true                              ,
              paidAt          : Date.now()                        ,
              orderStatus     : 'processing'                      ,
              isDelivered     : false                             ,
              deliveredAt     : 'n/a'                             ,
              shipping        : orderCostTotals?.shippingPrice    ,
              taxPrice        : orderCostTotals?.taxPrice         ,
              totalPrice      : parseInt(orderTotalPrice)         ,
              printfulOrderId : printfulOrderResponse?.data?.result?.id
            }

            dispatch(saveOrderInfo(orderDataPostPayment, dispatch))
            .then((saveOrderResponse) => {
    
              console.log('save order response', saveOrderResponse);
              if(saveOrderResponse?.status === 200){
                toast.success('Order Placed!');
                navigate('/profile/track-orders');
              }
            })
            .catch((error) => {
              toast.error('Order Not Saved, Contact Support');
              console.error(error.message);
            });

          }
        })
        .catch((error) => {
          toast.error('Print Order Not Processed, Contact Support');
          console.error(error.message);
        })
      } else {
        toast.error(response);
      };
    });
  };

  const handleTotalPriceCalculation = async() => {
    const getHostedImageUrlsForProductImages = async() => {

      const result = await Promise.all(
        cartItems?.map(async (product) => {
          try {
            const printedImageCloudinaryUrl = await storeImagesInCloudinary(product?.userImageUrl, userInfo?.user_id);
            
            return { 
              ...product, 
              completedBaseImageUrl: printedImageCloudinaryUrl?.data,
            };
          } catch (error) {
            console.error('Error:', error);
            return product;
          };
        })
      );

      return result;
    };

    const productsWithHostedImages = await getHostedImageUrlsForProductImages();
    
    // This needs to go back to just taking the completed images
    // with the order items and submitting the order to printful

    const orderReviewData = {
      user          : userInfo?.user_id       ,
      orderItems    : productsWithHostedImages,
      shipping      : shippingData            ,
      taxPrice      : 0                       ,
      shippingPrice : 0                       ,
      totalPrice    : itemsRetailPrice        ,
    };

    setOrderInfo(orderReviewData);
    dispatch(
      calculateOrderCostTotals(orderReviewData, shippingData, dispatch)
    );
  };

  useMemo(() => {

    let totalPrice = 0;

    cartItems.map((item) => {
      const quantity = item.quantity;
      const itemPrice = item.price;

      console.log('item', item);
      totalPrice = totalPrice + (itemPrice * quantity);
      return null;
    });

    setItemsRetailPrice(totalPrice);
  }, [cartItems]);

  useMemo(() => {
    calculateTotalRetailPrice();

    //eslint-disable-next-line
  }, [orderCostTotals]);

  const stripePaymentMethods = () => {
    return (
      <CardGridContainer>
        {
          userStripePaymentMethods?.map((paymentMethod) => (
            <Fade in timeout={1000} key={paymentMethod?.id}>
              <Grid
                item
                sx={{
                  minHeight: '3rem',
                  margin: '1rem',
                }}
              >
                <InformationCard sx={{position: 'relative'}} onClick={() => setSelectedPayment(paymentMethod)}>
                  <Radio
                    value     = { selectedPayment?.id }
                    checked   = { selectedPayment?.id === paymentMethod?.id }
                    onChange  = {() => setSelectedPayment(paymentMethod)}
                    color     = "secondary"
                    sx        = {{
                      position: 'absolute', 
                      top: 0, 
                      right: 0
                    }}
                  />
                  <InfoGrid container>
                    <CardImageGridContainer container>
                      { showCreditCardImage(paymentMethod?.card?.brand) }
                    </CardImageGridContainer>
                    <CardInfoTypography>...{paymentMethod?.card?.last4}</CardInfoTypography>
                    <CardInfoTypography>
                      {paymentMethod?.card?.exp_month}/{paymentMethod?.card?.exp_year}
                    </CardInfoTypography>
                  </InfoGrid>
                </InformationCard>
              </Grid>
            </Fade>
          ))
        }
        <Fade in timeout={1000}>
          <AddInfoCard>
            <AddInfoButton
              color   = 'secondary'
              sx      = {{
                display: 'flex', 
                justifyContent: 'space-evenly', 
                width: '100%',
                height: '100%'
              }}
              onClick={() => setPaymentView('Add')}
            >
              <AddBox sx={{ fontSize: '3rem' }} />
              <SecondaryTypography>Add Payment Method</SecondaryTypography>
            </AddInfoButton>
          </AddInfoCard> 
        </Fade>
      </CardGridContainer>
    )
  }

  const handleButtonsDisplay = () => {
    switch(optionsExpanded){
      case 0: 
        return(
          <CheckoutNavigationButtonContainer container>
            <Grid item>
              <Button 
                onClick={() => navigate('/cart')}
                color='secondary'
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained"
                color='secondary'
                onClick={() => setOptionsExpanded(1)}
                disabled={cartItems?.length < 1 || !shippingData?.zipCode}
              >
                Select Payment
              </Button>
            </Grid>
          </CheckoutNavigationButtonContainer>
        )
      case 1: 
        return(
          <CheckoutNavigationButtonContainer container>
            <Grid item>
              <Button 
                color='secondary'
                onClick={() => setOptionsExpanded(0)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained"
                color='secondary'
                onClick={() => {
                  setOptionsExpanded(2);
                  handleTotalPriceCalculation();
                }}
                disabled={!selectedPayment}
              >
                Review Order
              </Button>
            </Grid>
          </CheckoutNavigationButtonContainer>
        )
      case 2: 
        return(
          <CheckoutNavigationButtonContainer container>
            <Grid item>
              <Button 
                color='secondary'
                onClick={() => setOptionsExpanded(1)}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant   = "contained"
                color     = 'secondary'
                onClick   = {() => submitPaymentDispatcher()}
                disabled  = {shippingData?.addressOne === null}
              >
                Complete Order
              </Button>
            </Grid>
          </CheckoutNavigationButtonContainer>
        )
      default: return;
    }
  };

  console.log('orderCostTotals', orderCostTotals);
      
  return (
    <Grid 
      container
      sx={{
        display       : 'flex'         ,
        width         : '100%'         ,
        flexDirection : 'column'       ,
        justifyContent: 'space-between'
      }}
    >
      <Grid
        item 
        sx={{
          height        : '95%'          , 
          display       : 'flex'         , 
          flexDirection : 'column'       , 
          justifyContent: 'space-between',
          marginTop     : '2rem'         ,
          // marginLeft    : '1rem'         ,
          marginRight   : '1rem'         ,
          flex          : '9'            ,
          width         : '100%'
        }}
      >
        { (orderSubmitting || paymentSubmitting) ? (
          <CircularLoadingContainer>
            <CircularProgress color='primary' size={'10rem'}/>
          </CircularLoadingContainer>

          )  :  (
          
          <Grid container sx={{display: 'flex', flexDirection: 'column'}}>
          
            <OptionAccordion expanded = { optionsExpanded === 0 } >
              <OptionAccordionSummary aria-controls="style">
                <Typography variant='secondary'>Shipping</Typography>
              </OptionAccordionSummary>
              <OptionAccordionDetails>
                <Grid item xs={12}>
                  <ShippingModule
                    changeShipping            = { changeShipping            }
                    // shippingLikePayment       = { shippingLikePayment       }
                    shippingData              = { shippingData              }
                    setShippingData           = { setShippingData           }
                    handleShippingDataChange  = { handleShippingDataChange  }
                    setChangeShipping         = { setChangeShipping         }
                    userInfo                  = { userInfo                  }
                    saveShippingData          = { saveShippingData          }
                    // setShippingLikePayment    = { setShippingLikePayment    }
                  />
                </Grid>
              </OptionAccordionDetails>
            </OptionAccordion>
            <OptionAccordion expanded={optionsExpanded === 1} >
              <OptionAccordionSummary aria-controls="style">
                <Typography variant='secondary'>Payment Method</Typography>
              </OptionAccordionSummary>
              <OptionAccordionDetails>
                { 
                  ((paymentView === 'List')) ? 
                    stripePaymentMethods() : (
                    <PaymentStripe 
                      stripeCustomerId={userInfo?.stripeCustomerId}
                      updatePaymentView={setPaymentView}
                    />
                  )
                }
              </OptionAccordionDetails>
            </OptionAccordion>
            <OptionAccordion expanded={optionsExpanded === 2}  >
              <OptionAccordionSummary aria-controls="style">
                <Typography variant='secondary'>Order Summary</Typography>
              </OptionAccordionSummary>
              <OptionAccordionDetails>
                { ((cartItems?.length > 0 ) && !orderCostTotals?.isLoading && !orderCostTotals?.length && (!orderCostTotals?.total?.shipping)) ?

                  (
                    <>
                      <Grid container sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Grid item>
                          <Typography variant='h6'>Shipping - ${orderCostTotals?.totals?.shipping}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='h6'>Tax - ${orderCostTotals?.totals?.tax}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant='h6'>Total - ${(orderTotalPrice/100).toFixed(2)}</Typography>
                        </Grid>
                      </Grid>
                      {cartItems?.map((item) => (
                      <ProductOrderedCard>
                        <DetailsProductGridContainer container>
                          <ImageThumbnailContainer item>
                            <img 
                              src     = { item?.userImageUrl } 
                              alt     = '' 
                              height  = '100%' 
                              width   = '100%'
                              style   = {{
                                border: '1px solid gray'
                              }}
                            />
                          </ImageThumbnailContainer>
                        </DetailsProductGridContainer>
                        <Grid container sx={{display: 'flex', flex: '1', flexDirection: 'column'}}>
                          <Typography variant='secondary'>{item?.name}</Typography>
                          <Typography variant='secondary'>Price: ${(item?.price / 100).toFixed(2)}</Typography>
                          <Typography variant='secondary'>Qty: {item?.quantity}</Typography>
                        </Grid>
                      </ProductOrderedCard>
                    ))}
                  </>
                  ) : (
                    <CircularLoadingContainer sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
                      <CircularProgress color='secondary' />
                      <Typography>Calculating Order Totals</Typography>
                    </CircularLoadingContainer>
                  )
                }
              </OptionAccordionDetails>
            </OptionAccordion>
          </Grid>
        )}
      </Grid>
      <Grid 
        item 
        sx={{
          display: 'flex', 
          flex: '1', 
          justifyContent: 'flex-end', 
          margin: '1rem'
        }}
      >
        { handleButtonsDisplay() }
      </Grid>
    </Grid>
  );
};

export default PaymentAndShipping;