const colorOptions = [
  {id: 0, label: 'Rainbow', description: 'A bright and colorful palette with saturated hues.'},
  {id: 1, label: 'Pastels', description: 'Soft, light, and desaturated hues reminiscent of spring.'},
  {id: 2, label: 'Monochrome', description: 'Various shades of black and gray, with an emphasis on contrast.'},
  {id: 3, label: 'Sunset', description: 'Warm oranges, pinks, and purples, as seen in a sunset.'},
  {id: 4, label: 'Ocean', description: 'A range of blues and greens, evoking the sea.'},
  {id: 5, label: 'Green', description: 'Greens and browns that suggest a natural, earthy environment.'},
  {id: 6, label: 'Desert', description: 'Beiges, light browns, and warm tones, suggesting a desert scene.'},
  {id: 7, label: 'Neon', description: 'Bright, fluorescent colors that suggest artificial light.'},
  {id: 8, label: 'White', description: 'Whites, light blues, and silvers, suggesting a cold, wintry scene.'},
  {id: 9, label: 'Red', description: 'Warm reds, oranges, and yellows, suggesting autumn foliage.'},
  {id: 10, label: 'Gold', description: 'Golds and deep purples, suggesting luxury and royalty.'},
  {id: 11, label: 'Midnight', description: 'Dark blues and blacks, suggesting a night scene.'},
  {id: 12, label: 'Jewel', description: 'Deep, saturated colors like emerald green, sapphire blue, and ruby red.'},
  {id: 13, label: 'Metallic', description: 'Silvers, golds, and bronzes with a metallic finish.'},
  {id: 14, label: 'Sepia', description: 'Warm browns and tans, suggesting a vintage or historical scene.'},
  {id: 15, label: 'Maroon', description: 'Rich, brownish-red color.'},
  {id: 16, label: 'Olive', description: 'Dark, yellowish-green color.'},
  {id: 17, label: 'Teal', description: 'Medium to dark greenish-blue color.'},
  {id: 18, label: 'Lime', description: 'Vivid, light green'}
]

const styleOptions = [
  {id: 0, label: 'Bright', description: 'Vibrant and energetic.'},
  {id: 1, label: 'Dark', description: 'Intense and brooding.'},
  {id: 2, label: 'Fun', description: 'Playful and joyful.'},
  {id: 3, label: 'Mysterious', description: 'Intriguing and puzzling.'},
  {id: 4, label: 'Romantic', description: 'Passionate and expressive.'},
  {id: 5, label: 'Minimalistic', description: 'Stripped down and simple.'},
  {id: 6, label: 'Futuristic', description: 'Advanced and forward-thinking.'},
  {id: 7, label: 'Vintage', description: 'Classic and timeless.'},
  {id: 8, label: 'Rustic', description: 'Natural and unrefined.'},
  {id: 9, label: 'Elegant', description: 'Sophisticated and stylish.'},
  {id: 10, label: 'Gritty', description: 'Tough and unpolished.'},
  {id: 11, label: 'Whimsical', description: 'Quirky and fanciful.'},
  {id: 12, label: 'Dreamy', description: 'Soothing and ethereal.'},
  {id: 13, label: 'Gloomy', description: 'Dark and moody.'},
  {id: 14, label: 'Vibrant', description: 'Bright and lively.'},
  {id: 15, label: 'Calm', description: 'Relaxing and serene.'},
  {id: 16, label: 'Bold', description: 'Strong and striking.'},
  {id: 17, label: 'Dramatic', description: 'Exciting and powerful.'},
  {id: 18, label: 'Geometric', description: 'Using shapes and lines.'},
  {id: 19, label: 'Abstract', description: 'Non-representational and non-objective.'},
  {id: 20, label: 'Realistic', description: 'True to life and accurate.'},
  {id: 21, label: 'Fantasy', description: 'Imaginative and otherworldly.'},
  {id: 22, label: 'Horror', description: 'Scary and unsettling.'},
  {id: 23, label: 'Sci-fi', description: 'Inspired by science and technology.'},
  {id: 24, label: 'Adventure', description: 'Exciting and action-packed.'},
  {id: 25, label: 'Comic', description: 'Funny and humorous.'},
  {id: 26, label: 'Gothic', description: 'Dark and mysterious.'},
]

const lightingOptions = [
  {id: 0, label: 'Natural', description: 'Using natural light.'},
  {id: 1, label: 'Artificial', description: 'Using artificial light sources.'},
  {id: 2, label: 'Direct', description: 'Lighting coming straight from the source.'},
  {id: 3, label: 'Diffused', description: 'Lighting that is scattered and less harsh.'},
  {id: 4, label: 'Backlit', description: 'Lighting from behind the subject.'},
  {id: 5, label: 'Side-lit', description: 'Lighting from one side of the subject.'},
  {id: 6, label: 'Top-lit', description: 'Lighting from above the subject.'},
  {id: 7, label: 'Low-light', description: 'Minimal lighting.'},
  {id: 8, label: 'Bright', description: 'Abundant light.'},
  {id: 9, label: 'Soft', description: 'Lighting with less contrast between light and dark.'},
  {id: 10, label: 'Hard', description: 'Lighting with strong contrast between light and dark.'},
  {id: 11, label: 'Warm', description: 'Lighting with a yellow to red color temperature.'},
  {id: 12, label: 'Cool', description: 'Lighting with a blue to white color temperature.'},
  {id: 13, label: 'Neutral', description: 'Lighting that is neither warm nor cool.'},
  {id: 14, label: 'Spotlight', description: 'A focused beam of light.'},
  {id: 15, label: 'Floodlight', description: 'Unfocused light that covers a large area.'},
  {id: 16, label: 'Strobe', description: 'A flashing light.'},
  {id: 17, label: 'Fluorescent', description: 'Using fluorescent light sources.'},
  {id: 18, label: 'Incandescent', description: 'Using incandescent light sources.'},
  {id: 19, label: 'LED', description: 'Using LED light sources.'},
  {id: 20, label: 'Candlelight', description: 'Using candles as light sources.'},
  {id: 21, label: 'Twilight', description: 'Natural light during or after sunset, before night.'},
  {id: 22, label: 'Golden hour', description: 'The period shortly after sunrise or before sunset.'},
  {id: 23, label: 'Blue hour', description: 'The period of twilight in the morning or evening.'},
  {id: 24, label: 'Silhouette', description: 'A dark shape against a light background.'},
]

export {
  colorOptions,
  styleOptions,
  lightingOptions
}