import { createTheme } from '@mui/material';
// Colors

// Mains - Opacity
const primaryLight = 'rgba(165,127,187, 0.15)';
const primaryMedium = 'rgba(165,127,187, 0.25)';
const primaryFull = '#f2f2f2';

// Secondaries - Opacity
const secondaryLight = 'rgba(177, 194, 250, 0.15)'
const secondaryMedium = 'rgba(177, 194, 250, 0.25)'
const secondaryFull = '#3c6efa';

// White & Grays

const grayLight = 'rgba(73,73,73, 0.1)';
const grayMedium = 'rgba(73,73,73, 0.5)';
const grayFull = 'rgba(73,73,73, 1)';

const whiteLight = 'rgba(255, 255, 255, 0.1)';
const whiteMedium = 'rgba(255, 255, 255, 0.5)';
const whiteFull = 'rgba(255, 255, 255, 1)';

// Buttons
const buttonPrimary = `${secondaryFull}`;

// Box Shadows
const primaryInsetBoxShadow = 'inset 0px 3.5px 10px rgba(56,56,55, 0.5)';

// Main App Background
const mainBackground = `${primaryFull}`;

// Fonts
const primaryFontColor = 'black';
const secondaryFontColor = 'black';
const tabPrimaryFontColor = 'black';
const tabSecondaryFontColor = 'black';

const primaryFont = "Avenir";
const secondaryFont = "Avenir";
const tabFont = "Avenir";

// Create Theme

const theme = createTheme({
  palette: {
    common: {
      purple: `${primaryFull}`,
      blue: `${secondaryFull}`,
    },
    primary : {
      light: `${primaryLight}`,
      md: `${primaryMedium}`,
      main: `${primaryFull}`,
    },
    secondary: {
      light: `${secondaryLight}`,
      md: `${secondaryMedium}`,
      main: `${secondaryFull}`
    },
    grays: {
      light: `${grayLight}`,
      md: `${grayMedium}`,
      full: `${grayFull}`,
    },
    whites: {
      light: `${whiteLight}`,
      md: `${whiteMedium}`,
      full: `${whiteFull}`,
    }
  },

  typography: {
    h6: {
      fontFamily: `${primaryFont}`,
      fontWeight: 400,
      color: `${primaryFontColor}`
    },
    primary: {
      fontFamily: `${primaryFont}`,
      color: `${primaryFontColor}`
    },
    secondary: {
      fontFamily: `${secondaryFont}`,
      color: `${secondaryFontColor}`
    },
    tab: {
      fontFamily: `${tabFont}`,
      color: `${tabPrimaryFontColor}`
    },
    tabSecondary: {
      fontFamily: `${tabFont}`,
      color: `${tabSecondaryFontColor}`,
      opacity: '0.7',
      "&:hover": {
        backgroundColor: `${secondaryMedium}`,
        opacity: '1'
      },
    }
  },

  boxShadows: {
    inset: {
      boxShadow: `${primaryInsetBoxShadow}`,
    }
  },
  buttons: {
    primary: {
      color: `${whiteFull}`,
      transition: '0.4s ease-in-out',
      "&:hover": {
        background: `${buttonPrimary}`,
      }
    }
  },
  flexBox: {
    justifyAlignCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    justifyStartAlignCenter: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }
  },
  toolbars: {
    mobileNav: {
      height: '10dvh'
    },
    mobileTopToolbar: {
      height: '5vh'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: `${mainBackground} fixed`,
          backgroundPosition: 'bottom',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '90dvh',
          maxHeight: '-webkit-fill-available',
          width: '100%',
          marginTop: '3.5rem',
          padding: '0'
        },
        html: {
          minHeight: '100%'
        },
        '#root': {
          minHeight: '100%'
        },

        '*::-webkit-scrollbar': {
          width: '0.2rem'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: `${secondaryFull}`,
          outline: '1px solid slategrey',
          borderRadius: '1rem',
        },
        '.MuiToggleButtonGroup-grouped:not(:first-of-type)': {
          border: 'none',
          padding: 'inherit',
          margin: '0'
        },
        '.MuiToggleButtonGroup-grouped:first-of-type': {
          border: 'none',
          padding: 'inherit',
          margin: '0'
        }
      }
    },
    MuiGrid: {
      item: {
        display: 'flex',
        flexGrow: '1!important',
        flexShrink: '2',
        maxWidth: '100%!important',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }
    }
    
  }
});

export default theme ;