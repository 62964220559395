import React, { useMemo, useState } from 'react';
import { 
  Grid, 
  Typography,
  useMediaQuery,
  TextField,
  IconButton,
  Divider,
  Button,
  CircularProgress,
  Container
} from '@mui/material';
import { 
  EmailSubGridContainer,
  EmailSubSubmitButton,
  EmailSubTextGridItem,
  FeatureBox,
  FeatureBoxContentGrid,
  FeatureContainerBox,
  FeaturePaper, 
  FooterBox, 
  FooterContentGridContainer, 
  FooterLinksGridContainer, 
  FooterLogoGridItem, 
  FooterSocialLinksContainer, 
  HeaderTextGridItem, 
  HeroBox, 
  HeroGridContainer, 
  HeroHeaderContentText, 
  IndividualScreenFeatureGridItem, 
  InnerBox, 
  MainPageBox, 
  ScreenFeatureBoxInnerGridContainer, 
  ScreenFeatureContentContainer, 
  ScreenFeatureContentItem, 
  ScreenFeatureHeaderTypography, 
  SecondaryPageBox, 
  VideoBox 
} from './styled';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import InstagramIcon from '@mui/icons-material/Instagram';
import PrintIcon from '@mui/icons-material/Print';
import AppBarMenu from './AppBarMenu';
import { 
  HeroHeaderText, 
  emailSubscription, 
  featureBoxOne, 
  featureBoxThree, 
  featureBoxTwo, 
  footerLogoSrc, 
  heroImageSrc, 
  infoGraphSrc, 
  pageHeaderContent, 
  processStepsMobile, 
  screenFeatureOne, 
  screenFeatureOneSrc, 
  screenFeatureThree, 
  screenFeatureThreeSrc, 
  screenFeatureTwo, 
  screenFeatureTwoSrc 
} from './consts';
import { useDispatch, useSelector } from 'react-redux';
import { saveKlaviyoSubscribe } from '../../actions/klaviyo';


function V2Landing() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const klaviyoSubscription = useSelector((state) => state?.klaviyo);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  console.log('email index', email)

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(saveKlaviyoSubscribe(email, dispatch));
  };

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  function formatDate() {
    const date = new Date();
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    const localISOTime = (new Date(Date.now() - timezoneOffset)).toISOString().slice(0,-1);
    return localISOTime;
  }

  console.log(formatDate());
  
  const handleSubmitFormDisplay = () => {
    console.log('klaviyo sub', klaviyoSubscription)
    if(klaviyoSubscription?.isLoading){
      return (
        <Container 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid lightgray', 
            padding: '1rem', 
            borderRadius: '1rem'
          }}
        >
          <CircularProgress color='secondary' />
        </Container>
      )
    };

    if(klaviyoSubscription?.error){
      return (
        <Container sx={{border: '1px solid lightgray', padding: '1rem', borderRadius: '1rem'}}>
          <Typography variant='h6' textAlign='center' fontWeight='700' sx={{color: 'black'}}>
            Looks like you're already subscribed! Keep an eye on your email for further updates on release!
          </Typography>
        </Container>
      )
    };

    if(klaviyoSubscription?.email){
      return (
        <Container sx={{border: '1px solid lightgray', padding: '1rem', borderRadius: '1rem'}}>
          <Typography variant='h6' color='secondary' textAlign='center' fontWeight='700'>
            Your Email Was Saved! <br /> We will keep you updated on our progress!
          </Typography>
        </Container>
      )
    }

    return (
      <form 
        noValidate 
        autoComplete="off" 
        onSubmit={handleSubmit} 
        style={{
          width: '100%', 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row'
        }}
      >
        <TextField 
          id="email" 
          label="Email" 
          variant="outlined" 
          color='secondary'
          onChange={handleEmailChange} 
          sx={{
            width: isMobile ? '100%' : '25rem',
            maxWidth: '25rem',
            marginRight: isMobile ? '0' : '1rem',
            '& .MuiOutlinedInput-root': {
              borderRadius: '25px',
            },
            marginBottom: isMobile ? '2rem' : ''
          }} 
        />
        <EmailSubSubmitButton variant="contained" type="submit">
          Submit
        </EmailSubSubmitButton>
      </form>
    )
  }

  const handleProcessDisplay = () => {
    if(isMobile){
      return processStepsMobile.map((step) => (
        <Grid key={step.id} container sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem', paddingTop: '0', flexDirection: 'column'}}>
          <Grid item sx={{padding: '1rem'}}>
            <Typography variant='h5' sx={{textAlign: 'center'}}>
              {step.headerText}
            </Typography>
          </Grid>
          <Grid item sx={{padding: '1rem'}}>
            <img height='200rem' src={step.imageSrc} alt='stepImage' />
          </Grid>
          <Grid item sx={{width: '75%'}}>
            <Typography sx={{textAlign: 'center', marginBottom: '2rem'}}>
              {step.content}
            </Typography>
          {
            step.id < 4 && (
              <Divider variant='middle'/>
            )
          }
          </Grid>
        </Grid>
      ))
    };

    return (
      <Grid item sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img height='650rem' src={infoGraphSrc} alt='infograph' />
      </Grid>
    )
  }

  return (
    <MainPageBox>
      <AppBarMenu isMobile={isMobile} navigate={navigate}/>
      <SecondaryPageBox>
        {/* First Section */}
        <HeroBox>
          <HeroGridContainer container>
            <HeaderTextGridItem item>
              <HeroHeaderText />
              <HeroHeaderContentText>
                {pageHeaderContent}
              </HeroHeaderContentText>
            </HeaderTextGridItem>
            <Grid item>
              <img 
                width={isMobile? '300rem' : '1100rem'} 
                height={isMobile ? '200rem' : '750rem'} 
                src={heroImageSrc} 
                alt='hero image' 
              />
            </Grid>
          </HeroGridContainer>
        </HeroBox>
        {/* Second Section */}
        <FeatureContainerBox id='howitworks'>
          <InnerBox>
            <FeaturePaper elevation={3} >
              <FeatureBoxContentGrid container>
                <Grid item>
                  <AutoAwesomeIcon sx={{fontSize: '5rem', color: '#5273E8'}} />
                </Grid>
                <Grid item>
                  <Typography 
                    variant='h5' 
                    sx={{
                      fontWeight: '700', 
                      paddingBottom: '1rem'
                    }}
                  >
                    {featureBoxOne.headerText}
                  </Typography>
                </Grid>
                <Grid item sx={{height: '5rem'}}>
                  <Typography>
                    {featureBoxOne.content}
                  </Typography>
                </Grid>
              </FeatureBoxContentGrid>
            </FeaturePaper>
          </InnerBox>
          <InnerBox>
            <FeaturePaper elevation={3} sx={{backgroundColor: '#5273E8'}}>
              <FeatureBoxContentGrid container>
                <Grid item>
                  <ModelTrainingIcon sx={{fontSize: '5rem', color: '#ffffff'}} />
                </Grid>
                <Grid item>
                  <Typography 
                    variant='h5' 
                    color='#ffffff'
                    sx={{
                      fontWeight: '700', 
                      paddingBottom: '1rem'
                    }}
                  >
                    {featureBoxTwo.headerText}
                  </Typography>
                </Grid>
                <Grid item sx={{height: '5rem'}}>
                  <Typography color='#ffffff'>
                    {featureBoxTwo.content}
                  </Typography>
                </Grid>
              </FeatureBoxContentGrid>
            </FeaturePaper>
          </InnerBox>
          <InnerBox>
            <FeaturePaper elevation={3} >
              <FeatureBoxContentGrid container>
                <Grid item>
                  <PrintIcon sx={{fontSize: '5rem', color: '#5273E8'}} />
                </Grid>
                <Grid item>
                  <Typography 
                    variant='h5' 
                    sx={{
                      fontWeight: '700', 
                      paddingBottom: '1rem'
                    }}
                  >
                    {featureBoxThree.headerText}
                  </Typography>
                </Grid>
                <Grid item sx={{height: '5rem'}}>
                  <Typography>
                    {featureBoxThree.content}
                  </Typography>
                </Grid>
              </FeatureBoxContentGrid>
            </FeaturePaper>
          </InnerBox>
          
        </FeatureContainerBox>
        {/* Third Section */}
        <FeatureBox id='features'>
          <ScreenFeatureBoxInnerGridContainer container>
            <IndividualScreenFeatureGridItem item>
              <ScreenFeatureContentContainer container>
                <ScreenFeatureContentItem item>
                  <ScreenFeatureHeaderTypography variant={isMobile ? 'h4' : 'h3'}>
                    {screenFeatureOne.headerText}
                  </ScreenFeatureHeaderTypography>
                  <Typography sx={{textAlign: isMobile ? 'center' : 'left'}}>
                    {screenFeatureOne.content}
                  </Typography>
                </ScreenFeatureContentItem>
              </ScreenFeatureContentContainer>
              <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                  <img 
                    height={isMobile ? '200rem' : '400rem' }
                    src={screenFeatureOneSrc} 
                    alt='feature' 
                    style={{filter: 'drop-shadow(9px 11px 14px rgba(82,115,232, 0.1))'}}
                  />
                </Grid>
              </Grid>
            </IndividualScreenFeatureGridItem>
            <IndividualScreenFeatureGridItem item sx={{ flexDirection: isMobile && 'column-reverse!important' }}>
              <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                  <img 
                    height={isMobile ? '200rem' : '400rem' }
                    src={screenFeatureTwoSrc} 
                    alt='feature' 
                    style={{filter: 'drop-shadow(-9px 11px 14px rgba(82,115,232, 0.1))'}}
                  />
                </Grid>
              </Grid>
              <ScreenFeatureContentContainer container>
                <ScreenFeatureContentItem item>
                  <ScreenFeatureHeaderTypography variant={isMobile ? 'h4' : 'h3'}>
                    {screenFeatureTwo.headerText}
                  </ScreenFeatureHeaderTypography>
                  <Typography sx={{textAlign: isMobile ? 'center' : 'left'}}>
                    {screenFeatureTwo.content}
                  </Typography>
                </ScreenFeatureContentItem>
              </ScreenFeatureContentContainer>
            </IndividualScreenFeatureGridItem>
            <IndividualScreenFeatureGridItem item>
              <ScreenFeatureContentContainer container>
                <ScreenFeatureContentItem item>
                  <ScreenFeatureHeaderTypography variant={isMobile ? 'h4' : 'h3'}>
                    {screenFeatureThree.headerText}
                  </ScreenFeatureHeaderTypography>
                  <Typography sx={{textAlign: isMobile ? 'center' : 'left'}}>
                    {screenFeatureThree.content}
                  </Typography>
                </ScreenFeatureContentItem>
              </ScreenFeatureContentContainer>
              <Grid container sx={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                  <img 
                    height={isMobile ? '200rem' : '400rem' }
                    src={screenFeatureThreeSrc} 
                    alt='feature' 
                    style={{filter: 'drop-shadow(9px 11px 14px rgba(82,115,232, 0.1))'}}
                  />
                </Grid>
              </Grid>
            </IndividualScreenFeatureGridItem>
          </ScreenFeatureBoxInnerGridContainer>
        </FeatureBox>
        {/* Fourth Section */}
        <VideoBox id='process'>
          <Grid 
            container
            sx={{
              display: 'flex',
              flexDirection: 'column', 
              justifyContent: 'space-around'
            }}
          >
            <Grid item sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', height: '10rem', width: '100%'}}>
              <Typography variant='h4'>
                Process At A Glance
              </Typography>
              <Divider variant='middle' sx={{color: 'orange', width: '50%'}}/>
            </Grid>

            { handleProcessDisplay() }
          </Grid>
        </VideoBox>
        {/* Fifth Section */}
        <FeatureContainerBox sx={{padding: '5rem 0', backgroundColor: '#f6f8fa'}}>
          <EmailSubGridContainer container>
            <EmailSubTextGridItem item>
              <ScreenFeatureHeaderTypography variant={isMobile ? 'h5' : 'h4'}>
                {emailSubscription.headerText}
              </ScreenFeatureHeaderTypography>
              <Typography sx={{textAlign: 'center', color: 'gray'}}>
                {emailSubscription.content}
              </Typography>
            </EmailSubTextGridItem>
            <Grid item sx={{width: '100%', paddingTop: '2rem'}} id='subscribe'>
              { handleSubmitFormDisplay() }
            </Grid>
          </EmailSubGridContainer>
        </FeatureContainerBox>
        {/* Footer */}
        <FooterBox>
          <FooterContentGridContainer container>
            <FooterLogoGridItem item>
              <img height='50rem' src={footerLogoSrc} alt='logo' />
            </FooterLogoGridItem>
            {
              !isMobile && (
                <FooterLinksGridContainer container>
                  <Grid item>
                    <Button sx={{color: '#ffffff'}} href='#howitworks'>
                      How It Works
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button sx={{color: '#ffffff'}} href='#features'>
                      Features
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button sx={{color: '#ffffff'}} href='#process'>
                      Process
                    </Button>
                  </Grid>
                </FooterLinksGridContainer>
              )
            }
            <FooterSocialLinksContainer container>
              <Grid item>
                <IconButton 
                  sx={{backgroundColor: '#3c6efa'}}
                  href='https://www.instagram.com/chris_rappa/'
                >
                  <InstagramIcon sx={{color: '#ffffff'}}/>
                </IconButton>
              </Grid>
            </FooterSocialLinksContainer>
          </FooterContentGridContainer>
        </FooterBox>
      </SecondaryPageBox>
    </MainPageBox>
  )
}

export default V2Landing