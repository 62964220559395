import { Typography } from "@mui/material";

export const pageHeaderContent = `Empower your customers to use AI for visualizing ideas, minimizing initial discussions and enhancing design precision. Our platform facilitates efficient designer/client collaboration to emphasize
focusing on refining and finalizing concepts.`;

export const featureBoxOne = {
  headerText: 'Iterate Merch Design with AI',
  content: 'Utilize AI to rapidly iterate and refine merchandise designs, seamlessly integrating generated art into product templates for designer enhancement and finalization.'
};

export const featureBoxTwo = {
  headerText: 'Build Merch Product Mockup',
  content: 'Create realistic merchandise mockups by selecting products, positioning artwork, and adding logos to visualize the final product design.'
};

export const featureBoxThree = {
  headerText: 'Print-On-Demand to Test Merch',
  content: 'Effortlessly order product samples using integrated print-on-demand services to test and refine your merchandise before launching.'
};

export const screenFeatureOne = {
  headerText: 'Easy AI Design Wizard',
  content: 'Seamlessly craft captivating merchandise designs with our intuitive AI art generator, ensuring superior quality visuals and streamlined design processes.'
};

export const screenFeatureTwo = {
  headerText: 'Accelerated Designer/Client Collab',
  content: 'Unlock seamless collaboration with CommuniEase, your gateway to clear communication between customers and designers. Minimize misunderstandings, enhance clarity, and accelerate project timelines effortlessly.'
};

export const screenFeatureThree = {
  headerText: 'Short Run Samples',
  content: 'Seamlessly connect designers and customers for swift feedback, iterative revisions, and unparalleled design excellence. Revolutionize your design process today.'
};

export const emailSubscription = {
  headerText: 'Get A Free Print Sample Upon App Launch',
  content: `Sign up now to receive a free sample of our product when we launch, limited quantities available!`
};

export const heroImageSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713214492/AIGearSkin/Internal%20Images/yearmerchaiherotransparent_qhtm7d.png';
export const screenFeatureOneSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713219958/AIGearSkin/Internal%20Images/artgenscreen_keubey.png';
export const screenFeatureTwoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713220015/AIGearSkin/Internal%20Images/collabscreen_y7ukjg.png';
export const screenFeatureThreeSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713220060/AIGearSkin/Internal%20Images/orderscreen_nruws4.png';
export const infoGraphSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713288952/AIGearSkin/Internal%20Images/infograph_zqlrov.png';
export const footerLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713312503/AIGearSkin/Internal%20Images/2_acf2hk.png';
export const headerLogoSrc = 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713291955/AIGearSkin/Internal%20Images/1_bj3sls.png';

export const processStepsMobile = [
  {
    id: 0,
    headerText: 'Customer Creates Initial Mock Up',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713307316/AIGearSkin/Internal%20Images/steponeprocess_c2rsmz.png',
    content: 'The client uses the AI Art Generator and our easy to use Mock Up Wizard to initiate design refinement process.'
  },
  {
    id: 1,
    headerText: 'Collab with Customer',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713307322/AIGearSkin/Internal%20Images/step2process_fkh2hj.png',
    content: 'Designer and client can chat directly on product Mock Up to ensure proper direction for refinement.'
  },
  {
    id: 2,
    headerText: 'Designer Refines Design',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713307325/AIGearSkin/Internal%20Images/step3process_ar9owz.png',
    content: 'Designer updates Mock Up with refined design and further discusses via chat with client'
  },
  {
    id: 3,
    headerText: 'Order Samples',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713307323/AIGearSkin/Internal%20Images/step4process_mqgkwd.png',
    content: 'Once final design is decided designer orders samples from our Print-On-Demand Shop for customer to review'
  },
  {
    id: 4,
    headerText: 'Live Production',
    imageSrc: 'https://res.cloudinary.com/djrbfvpit/image/upload/v1713307320/AIGearSkin/Internal%20Images/step5process_n7etzw.png',
    content: 'After the sample is approved, use our Print-On-Demand fulfillment and import product to online store or download Mock Up to send to desired fulfillment center.'
  },
]
export const HeroHeaderText= () => {
  return (
    <Typography variant='h3' sx={{width: '100%', textAlign: 'center', padding: '0.25rem', marginTop: '1rem', marginBottom: '1rem', fontWeight: '700'}}>
      Trim Time: Unleash <span style={{color: '#5273e7'}}> AI </span> <br /> Sampler <span style={{color: '#5273e7'}}> Solution! </span>
    </Typography>
  )
}