import InventoryIcon from '@mui/icons-material/Inventory';
import ImageIcon from '@mui/icons-material/Image';
import PaletteIcon from '@mui/icons-material/Palette';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import BrushIcon from '@mui/icons-material/Brush';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
// import SaveAltIcon from '@mui/icons-material/SaveAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
// import FavoriteIcon from '@mui/icons-material/Favorite';
import BorderOuterIcon from '@mui/icons-material/BorderOuter';

export const mobileDrawerMenuOptions = [
  {id: 1, name: "Create Art", link: '/design', icon: <LooksOneIcon sx={{fontSize: '1.5rem'}} />}, 
  {id: 2, name: "Upload Logo", link: '/design/add-logo', icon: <LooksTwoIcon sx={{fontSize: '1.5rem'}}/>}, 
  {id: 3, name: "Select Product", link: '/catalog', icon: <Looks3Icon sx={{fontSize: '1.5rem'}} />},
  {id: 4, name: "Place Art", link: '/design/placement/0', icon: <Looks4Icon sx={{fontSize: '1.5rem'}}/>}, 
  {id: 5, name: "Saved Artwork", link: '/profile/saved-artwork', icon: <ImageIcon />}, 
  // {id: 6, name: "Favorite Products", link: '/profile/fav-products', icon: <FavoriteIcon />}, 
  {id: 7, name: "Track Orders", link: '/profile/track-orders', icon: <InventoryIcon />},       
  // {id: 8, name: "Mockup", link: '/mockup', icon: <AutoModeIcon sx={{fontSize: '1.5rem'}} />},       
  {id: 9, name: "Cart", link: '/cart', icon: <ShoppingCartIcon sx={{fontSize: '1.5rem'}} />},       
  {id: 10, name: "Support", link: '/support', icon: <ContactSupportIcon sx={{fontSize: '1.5rem'}} /> }
];

export const baseMobileBottomNav = [
  {id: 0, name: "Catalog", link: '/catalog', icon: <MenuBookIcon sx={{fontSize: '1.5rem', color: 'white'}} />},
  {id: 1, name: "Design", link: '/design', icon: <PaletteIcon sx={{fontSize: '1.5rem', color: 'white'}} />}, 
  // {id: 2, name: "Mockup", link: '/mockup', icon: <AutoModeIcon sx={{fontSize: '1.5rem'}} />}, 
  {id: 3, name: "Cart", link: '/cart', icon: <ShoppingCartIcon sx={{fontSize: '1.5rem', color: 'white'}} />},    
];

export const desktopAccountDropdownMenu = [
  {id: 0, name: "Saved Artwork", link: '/profile', icon: <ImageIcon />}, 
  // {id: 1, name: "Favorite Products", link: '/profile', icon: <FavoriteIcon />}, 
  {id: 2, name: "Track Orders", link: '/profile', icon: <InventoryIcon />},       
];

export const designMobileMenu = [
  {id: 0, name: "Back", link: '/catalog', icon: <ArrowBackIcon sx={{fontSize: '1.5rem', color: 'white'}}/>}, 
  {id: 1, name: "Create", link: '/design', icon: <BrushIcon sx={{fontSize: '1.5rem', color: 'white'}}/>}, 
  {id: 2, name: "Logo", link: '/design/add-logo', icon: <InsertPhotoIcon sx={{fontSize: '1.5rem', color: 'white'}}/>}, 
  {id: 3, name: "Place", link: '/design/placement/0', icon: <OpenWithIcon sx={{fontSize: '1.5rem', color: 'white'}}/>},       
];

export const desktopMenuUserFlow = [
  {id: 1, name: "Create Art", link: '/design', icon: <LooksOneIcon sx={{fontSize: '1.5rem'}} />}, 
  {id: 2, name: "Upload Logo", link: '/design/add-logo', icon: <LooksTwoIcon sx={{fontSize: '1.5rem'}}/>}, 
  {id: 3, name: "Select Product", link: '/catalog', icon: <Looks3Icon sx={{fontSize: '1.5rem'}} />},
  {id: 4, name: "Place Art", link: '/design/placement/0', icon: <Looks4Icon sx={{fontSize: '1.5rem'}}/>}, 
  {id: 5, name: "Saved Artwork", link: '/profile/saved-artwork', icon: <FolderSpecialIcon />}, 
  {id: 6, name: "Product Templates", link: '/your-products', icon: <BorderOuterIcon />}, 
  {id: 7, name: "Track Orders", link: '/profile/track-orders', icon: <LocalShippingIcon />},       
  {id: 8, name: "Cart", link: '/cart', icon: <ShoppingCartIcon sx={{fontSize: '1.5rem'}} />},  
  {id: 9, name: "Support", link: '/support', icon: <ContactSupportIcon sx={{fontSize: '1.5rem'}} />}
]