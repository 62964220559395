import React from 'react'
import ContentArea from '../../components/ContentArea';
import Box from '@mui/material/Box';
import CreateArtwork from '../../containers/CreateArtwork';


function Design() {

  return (
    <Box 
      component="main" 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        flexGrow: 5, 
      }}
    >
      <ContentArea currentStepComponent={<CreateArtwork />} />
    </Box>
  );
}

export default Design;