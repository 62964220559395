export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAIL = 'CREATE_PAYMENT_METHOD_FAIL';

export const PAYMENT_SUBMISSION_REQUEST = 'PAYMENT_SUBMISSION_REQUEST';
export const PAYMENT_SUBMISSION_SUCCESS = 'PAYMENT_SUBMISSION_SUCCESS';
export const PAYMENT_SUBMISSION_FAIL = 'PAYMENT_SUBMISSION_FAIL';

export const UPDATE_PAYMENT_DATA_REQUEST = 'UPDATE_PAYMENT_DATA_REQUEST';
export const UPDATE_PAYMENT_DATA_SUCCESS = 'UPDATE_PAYMENT_DATA_SUCCESS';
export const UPDATE_PAYMENT_DATA_FAIL = 'UPDATE_PAYMENT_DATA_FAIL';

export const CARD_DELETE_REQUEST = 'CARD_DELETE_REQUEST';
export const CARD_DELETE_SUCCESS = 'CARD_DELETE_SUCCESS';
export const CARD_DELETE_FAIL = 'CARD_DELETE_FAIL';

export const SHIPPING_INFO_SAVE_REQUEST = 'SHIPPING_INFO_SAVE_REQUEST';
export const SHIPPING_INFO_SAVE_SUCCESS = 'SHIPPING_INFO_SAVE_SUCCESS';
export const SHIPPING_INFO_SAVE_FAIL = 'SHIPPING_INFO_SAVE_FAIL';

export const UPDATE_SHIPPING_INFO_REQUEST = 'UPDATE_SHIPPING_INFO_REQUEST';
export const UPDATE_SHIPPING_INFO_SUCCESS = 'UPDATE_SHIPPING_INFO_SUCCESS';
export const UPDATE_SHIPPING_INFO_FAIL = 'UPDATE_SHIPPING_INFO_FAIL';

export const SHIPPING_DELETE_REQUEST = 'SHIPPING_DELETE_REQUEST';
export const SHIPPING_DELETE_SUCCESS = 'SHIPPING_DELETE_SUCCESS';
export const SHIPPING_DELETE_FAIL = 'SHIPPING_DELETE_FAIL';

export const USER_INFO_SAVE_REQUEST = 'USER_INFO_SAVE_REQUEST';
export const USER_INFO_SAVE_SUCCESS = 'USER_INFO_SAVE_SUCCESS';
export const USER_INFO_SAVE_FAIL = 'USER_INFO_SAVE_FAIL';

export const USER_ARTWORK_SAVE_REQUEST = 'USER_ARTWORK_SAVE_REQUEST';
export const USER_ARTWORK_SAVE_SUCCESS = 'USER_ARTWORK_SAVE_SUCCESS';
export const USER_ARTWORK_SAVE_FAIL = 'USER_ARTWORK_SAVE_FAIL';

export const USER_LOGO_SAVE_REQUEST = 'USER_LOGO_SAVE_REQUEST';
export const USER_LOGO_SAVE_SUCCESS = 'USER_LOGO_SAVE_SUCCESS';
export const USER_LOGO_SAVE_FAIL = 'USER_LOGO_SAVE_FAIL';

export const USER_LOGO_DELETE_REQUEST = 'USER_LOGO_DELETE_REQUEST';
export const USER_LOGO_DELETE_SUCCESS = 'USER_LOGO_DELETE_SUCCESS';
export const USER_LOGO_DELETE_FAIL = 'USER_LOGO_DELETE_FAIL';

export const USER_ARTWORK_DELETE_REQUEST = 'USER_ARTWORK_DELETE_REQUEST';
export const USER_ARTWORK_DELETE_SUCCESS = 'USER_ARTWORK_DELETE_SUCCESS';
export const USER_ARTWORK_DELETE_FAIL = 'USER_ARTWORK_DELETE_FAIL';

export const SUBTRACT_CREDITS_REQUEST = 'SUBTRACT_CREDITS_REQUEST';
export const SUBTRACT_CREDITS_SUCCESS = 'SUBTRACT_CREDITS_SUCCESS';
export const SUBTRACT_CREDITS_FAIL    = 'SUBTRACT_CREDITS_FAIL'   ;