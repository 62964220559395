import React, { 
  useMemo, 
  useRef, 
  useState 
} from 'react';
import { 
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid, 
  Step, 
  Stepper,
  useMediaQuery,
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OptionMenu from './OptionMenu';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { 
  CircularLoadingContainer, 
  CustomStepLabel, 
  ImageAreaGrid, 
  MainGridContainer, 
  OptionSelectionContainer, 
  OrientationContainer, 
  PlaceArtNavigation, 
  PlacementBackButton, 
  PromptOptionsContainer, 
  QontoConnector, 
  QontoStepIconRoot, 
  SecondaryTypography, 
  SelectAndSaveContainer
} from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { 
  storeTemplateData 
} from '../../actions/printfulActions.js';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-image-crop/dist/ReactCrop.css';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { storeArtworkPlacement } from '../../actions/cartActions';
import { handleCombineBackgroundAndLogo, handleCreateImageFromPlacement } from './helpers';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import { useToast } from '../../libs/toast';

function ImagePlacementEditor() {

  const dispatch          = useDispatch()            ;
  const navigate          = useNavigate()            ;
  const { id: stepValue } = useParams()              ;
  const stepParseInt      = parseInt(stepValue, 10)  ;
  const toast             = useToast()               ;

	const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const userProducts         = useSelector((state) => state?.cartInfo?.cartItems)                     ;
  const userGenArtwork       = useSelector((state) => state?.userData?.loginInfo?.generatedArtworks)  ;
  const userLogos            = useSelector((state) => state?.userData?.loginInfo?.logos)              ;
  const templateData         = useSelector((state) => state?.productInfo?.editProductTemplate)        ;

  const [activeStep, setActiveStep]                   = useState(stepParseInt)   ;
  const [displayedImage, setDisplayedImage]           = useState('')             ; 
  // const [updatingImage, setUpdatingImage]             = useState(false)          ;
  const [artworkBackground, setArtworkBackground]     = useState()               ;
  const [imageProcessing, setImageProcessing]         = useState()               ;
  const [selectedProductData, setSelectedProductData] = useState()               ;
  const [artworkPlacementDimensions, setArtworkPlacementDimenstion] = useState() ;
  const [dragPosition, setDragPosition]               = useState({ x: 0, y: 0 }) ;

  const templateRef      = useRef();
  const dragRef          = useRef();
  const resizeRef        = useRef();
  const printAreaRef     = useRef();
  const logoPrintAreaRef = useRef();


  const steps = ['Select Product', 'Place Art', 'Place Logo'];

  
  const getArtworkUrls = () => {
    return userGenArtwork?.map((work) => {
      return { url: work?.upscaledImageUrl};
    });
  };

  const getLogoUrls = () => {
    return userLogos?.map((logo) => {
      return { url: logo?.cloudinaryUrl};
    });
  };

  const getNeededProductData = () => {
    return userProducts?.map((product) => {
      return { 
        url           : product?.selectedVariant?.image,
        productId     : product?.id                    ,
        variantId     : product?.selectedVariant?.id   ,
        cartId        : product?.cartId                ,
        completeImage : product?.userImageUrl          ,
      };
    });
  };

  const handleSaveArtworkPlacedImage = (image) => {

    dispatch(
      storeArtworkPlacement(
        image ?? artworkBackground    ,
        selectedProductData?.variantId, 
        selectedProductData?.cartId   ,
        artworkPlacementDimensions    ,
        templateData                  ,
        dispatch
      )
    ).then(() => {
      toast.success('Image Placements Complete!');
    });
  };
  
  const handleStepProgression = async(newValue) => {
    console.log('new value in handler', newValue)
    if(newValue === 2){
      try{
        await handleCreateImageFromPlacement(
          printAreaRef                              ,
          dragRef                                   ,
          resizeRef                                 ,
          templateData                              ,
          setArtworkBackground                      ,
          displayedImage                            ,
          setArtworkPlacementDimenstion
        )
        .then(() => {
          toast.success('Artwork Placed!');
        })
      } catch(err){
        console.error(err);
        toast.error(err.message);
      };
    };

    if(newValue === 3 && displayedImage){
      try{
        const logocombined = await handleCombineBackgroundAndLogo(
          templateData     ,
          logoPrintAreaRef ,
          dragRef          ,
          resizeRef        ,
          artworkBackground,
          displayedImage   ,
          setArtworkPlacementDimenstion
        )
        
        await handleSaveArtworkPlacedImage(logocombined);
        
      } catch(err){
        console.error(err);
        toast.error(err.message);
      };
    };

    if(newValue === 3 && !displayedImage){
      handleSaveArtworkPlacedImage(null);
    };

    setImageProcessing(false);    
    setActiveStep(newValue);
  };

  const handleOptionChange = (
    optionType, 
    productId , 
    img       , 
    variantId ,
    cartId
  ) => {
    if(optionType === "Products"){

      setSelectedProductData({
        variantId : variantId,
        productId : productId,
        cartId    : cartId
      });

      dispatch(
        storeTemplateData(productId, variantId, dispatch)
      );
    };

    setDisplayedImage(img);
  };

  const addMoreCredits = () => {
    navigate('/profile/info');
  };

  useMemo(() => {
    setDisplayedImage('');
    switch(activeStep){
      case 0: 
        navigate('/design/placement/0');
      break;
      case 1: 
        navigate('/design/placement/1');
      break;
      case 2: 
        navigate('/design/placement/2');
      break;
      case 3: 
        navigate('/design/placement/3');
      break;
      default: navigate('/design/placement/0');
    }

  // eslint-disable-next-line
  }, [activeStep]);

  const handleAlignmentOptionSelection = (optionType) => {

    switch(optionType){
      case 'verticalTop': 
        handleDrag('', 
          {
            x: dragPosition?.x, 
            y: 0
          }
        )
      break;
      case 'verticalBottom': 
        handleDrag('', 
          {
            x: dragPosition?.x, 
            y: templateData?.print_area_height - resizeRef.current.state.height
          }
        )
      break;
      case 'verticalCenter': 
        handleDrag('', 
          {
            x: dragPosition?.x,
            y: (templateData?.print_area_height - resizeRef.current.state.height) / 2
          }
        )
      break;
      case 'horizontalLeft': 
        handleDrag('', 
          {
            x: 0, y: dragPosition?.y
          }
        )
      break;
      case 'horizontalRight': 
        handleDrag('', 
          {
            x: templateData?.print_area_width - resizeRef.current.state.width,
            y: dragPosition?.y
          }
        )
      break;
      case 'horizontalCenter': 
        handleDrag('',
          {
            x: (templateData?.print_area_width - resizeRef.current.state.width) / 2,
            y: dragPosition?.y
          }
        )
      break;
      default: return;
    }
  };

  const handleDrag = (event, dragData) => {
    const { x, y } = dragData;
    setDragPosition({ x, y });
  };

  useMemo(() => {
    if(!templateData?.template_id){
      navigate('/design/placement/0');
      setActiveStep(0);
    };

    // eslint-disable-next-line
  }, [templateData]);

  const placeArtworkWrapper = () => {

    if(!displayedImage){
      return;
    };

    return (
      <div 
        style  = {{  transform: isMobile ? 'scale(0.3)' : 'scale(0.4)'}}>
        <div 
          ref = { templateRef }
          style = {{
            height          : templateData?.template_height ?? "20rem" , 
            width           : templateData?.template_width ?? "20rem"  , 
            position        : 'relative'                               , 
            overflow        : 'hidden'                                 ,
            backgroundImage : `url(${templateData?.image_url})`        , 
            backgroundSize  : 'cover'                                  ,
          }}
        >
          <div 
            style = {{ 
              width     : templateData?.print_area_width  , 
              height    : templateData?.print_area_height ,
              left      : templateData?.print_area_left   ,
              right     : templateData?.print_area_right  ,
              top       : templateData?.print_area_top    ,
              position  : 'relative'
            }}
            ref = { printAreaRef }
          >
          <Draggable
            style = {{
              position: 'absolute'                      ,
              width   : templateData?.print_area_width  ,
              height  : templateData?.print_area_height ,
            }}
            bounds = {  'parent'  }
            ref = { dragRef }
            onDrag ={handleDrag}
            position={dragPosition}
          >
            <Resizable
              defaultSize = {{
                width: 200,
                height: 200
              }}
              style = {{
                backgroundImage : `url(${displayedImage})` ,
                backgroundSize  : 'cover'                  ,
                backgroundRepeat: 'no-repeat'
              }}
              lockAspectRatio = {  false  }
              maxHeight       = { '100%'  }
              maxWidth        = { '100%'  }
              minHeight       = { '50px'  }
              minWidth        = { '50px'  }
              handleComponent = {{
                topRight    : <Brightness1Icon sx = {{color: 'grey'}}/>,
                topLeft     : <Brightness1Icon sx = {{color: 'grey'}}/>,
                bottomLeft  : <Brightness1Icon sx = {{color: 'grey'}}/>,
                bottomRight : <Brightness1Icon sx = {{color: 'grey'}}/>
              }}
              ref = { resizeRef }
            />
          </Draggable>
          </div>
        </div>
      </div>
    )
  };

  const placeLogoWrapper = () => {

    if(!displayedImage){
      return;
    };

    return (
      <div style  = {{transform: isMobile ? 'scale(0.3)' : 'scale(0.4)' }}>
        <div 
          ref = {templateRef}
          style={{
            height          : templateData?.template_height ?? "20rem", 
            width           : templateData?.template_width ?? "20rem" , 
            position        : 'relative'                              , 
            overflow        : 'hidden'                                ,
            backgroundImage : `url(${templateData?.image_url})`       ,
            backgroundSize  : 'cover'
          }}
        >
          <div 
            style = {{ 
              width           : templateData?.print_area_width  , 
              height          : templateData?.print_area_height ,
              left            : templateData?.print_area_left   ,
              right           : templateData?.print_area_right  ,
              top             : templateData?.print_area_top    ,
              position        : 'relative'                      ,
              backgroundImage : `url(${artworkBackground})`     ,
              backgroundSize  : 'cover'                         ,
              border          : '1px solid gray'
            }}
            ref = {logoPrintAreaRef}
          >
            <Draggable
              style  =  {{
                position: 'absolute'                      ,
                width   : templateData?.print_area_width  ,
                height  : templateData?.print_area_height ,
              }}
              bounds    = { 'parent'      }
              ref       = { dragRef       }
              onDrag    = { handleDrag    }
              position  = { dragPosition  }
            >
              <Resizable
                defaultSize = {{
                  width : 250,
                  height: 250
                }}
                style = {{
                  backgroundImage : `url(${displayedImage})`,
                  backgroundSize  : '100% 100%',
                  backgroundRepeat: 'no-repeat'
                }}
                lockAspectRatio = {false}
                maxHeight       = {'100%'}
                maxWidth        = {'100%'}
                minHeight       = {'50px'}
                minWidth        = {'50px'}
                handleComponent={{
                  topRight    : <Brightness1Icon sx = {{  color: 'grey' }} />,
                  topLeft     : <Brightness1Icon sx = {{  color: 'grey' }} />,
                  bottomLeft  : <Brightness1Icon sx = {{  color: 'grey' }} />,
                  bottomRight : <Brightness1Icon sx = {{  color: 'grey' }} />
                }}
                ref = {resizeRef}
              />
            </Draggable>
          </div>
        </div>
      </div>
    )
  };

  const handleImageAreaDisplay = () => {
    switch(activeStep){
      case 0: 
        if(displayedImage){
          return (
            <img
              src   = { displayedImage }
              alt   = "Existing"
              id    = "existing-image"
              style = {{
                display   : 'flex' ,
                width     : '100%' ,
                height    : '100%' ,
              }}
            />
          )
        };

        if(!displayedImage){
          return (
            <WallpaperIcon
              sx  = {{
                fontSize: isMobile ? '10rem' : '20rem', 
                color   : 'rgba(203,203,203, 0.75)' ,
                marginTop: '1rem'
              }} 
            />
          )
        };
      break;
      case 1: 
        if(displayedImage){
          return (
            placeArtworkWrapper()
          )
        };

        if(!displayedImage){
          return (
            <WallpaperIcon
              sx  = {{
                fontSize  : isMobile ? '10rem' : '20rem', 
                color     : 'rgba(203,203,203, 0.75)'
              }} 
            />
          )
        };
      break;
      case 2: 
        if(displayedImage){
          return (
            placeLogoWrapper()
          )
        };

        if(!displayedImage){
          return (
            <WallpaperIcon
              sx  = {{
                fontSize: isMobile ? '10rem' : '20rem', 
                color   : 'rgba(203,203,203, 0.75)'
              }} 
            />
          )
        };
      break;
      case 3: 
        return (
          <Container
            sx  = {{
              display       : 'flex'        ,
              flexDirection : 'column'      ,
              height        : '100%'        ,
              width         : '100%'        ,
              justifyContent: 'space-evenly',
              alignItems    : 'center'
            }}
          >
            <Button 
              variant = 'contained'
              color   = 'secondary'
              onClick = {() => setActiveStep(activeStep - 1)}
              sx      = {{
                width : '100%',
                height: '25%'
              }}
            >
              <SecondaryTypography sx={{fontSize: '1rem'}}>
                Go Back
              </SecondaryTypography>
            </Button>
            <Button 
              variant = 'contained' 
              color   = 'secondary'
              onClick = {() => setActiveStep(0)}
              sx      = {{
                width : '100%',
                height: '25%'
              }}
            >
              <SecondaryTypography sx={{fontSize: '1rem'}}>
                Edit Another Product
              </SecondaryTypography>
            </Button>
            <Button
              variant = 'contained'
              color   = 'secondary'
              onClick = {() => navigate('/cart')}
              sx      = {{
                width: '100%',
                height: '25%'
              }}
            >
              <SecondaryTypography sx={{fontSize: '1rem'}}>
                View Cart
              </SecondaryTypography>
            </Button>
          </Container>
        );
      
      default: return;
    };
    
  };

  const QontoStepIcon = (props) => {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState = {{ active }} className  = {className}>
        { completed ? (
          <Check className  = "QontoStepIcon-completedIcon" />
        ) : (
          <div className    = "QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const handleEditOptionsDisplay = () => {
    switch(activeStep){
      case 0: 
        return (
          <Button
            variant   = "contained"
            color     = "secondary"
            onClick   = {() => handleStepProgression(activeStep + 1)}
            disabled  = {!displayedImage}
          >
            <SecondaryTypography>
              Edit Product
            </SecondaryTypography>
          </Button>
        );
      case 1: 
        return (
          <Grid container sx={{height: '100%', width: '100%'}}>
            <Grid item sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  onClick={() => handleAlignmentOptionSelection('verticalBottom')}
                >
                  <VerticalAlignBottomIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('verticalCenter')}
                >
                  <VerticalAlignCenterIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('verticalTop')}
                >
                  <VerticalAlignTopIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('horizontalCenter')}
                >
                  <AlignHorizontalCenterIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('horizontalLeft')}
                >
                  <AlignHorizontalLeftIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('horizontalRight')}
                >
                  <AlignHorizontalRightIcon />
                </Button>
              </ButtonGroup>
            </Grid>
            <SelectAndSaveContainer item>
              <PlaceArtNavigation container>
                <Grid item sx = {{  padding: '0.5rem', paddingLeft: '0' }}>
                  <PlacementBackButton 
                    variant = 'contained'
                    color   = 'secondary' 
                    onClick = {() => handleStepProgression(activeStep - 1)}
                  >
                    <ArrowBackIcon />
                  </PlacementBackButton>
                </Grid>
                <Grid item>
                { imageProcessing ? 
                  (
                    <CircularLoadingContainer>
                      <CircularProgress 
                        color         = 'secondary' 
                        size          = {'2rem'} 
                        thickness     = {4} 
                        sx            = {{animationDuration: '550ms'}}
                        disableShrink

                      />
                    </CircularLoadingContainer>
                  ) : (
                    <Button 
                      variant   = 'contained'
                      color     = 'secondary'
                      disabled  = {imageProcessing || !displayedImage}
                      onClick   = {() => {
                        setImageProcessing(true)              ;   
                        handleStepProgression(activeStep + 1) ;
                      }}
                      sx={{cursor: 'pointer'}}
                    >
                      <SecondaryTypography>
                        Save Art Placement
                      </SecondaryTypography>
                    </Button>
                  )
                }
                </Grid>
              </PlaceArtNavigation>
            </SelectAndSaveContainer>
          </Grid>
          
        );
      case 2: 
        return (
          <Grid container sx={{height: '100%', width: '100%'}}>
            <Grid item sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button
                  onClick={() => handleAlignmentOptionSelection('verticalBottom')}
                >
                  <VerticalAlignBottomIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('verticalCenter')}
                >
                  <VerticalAlignCenterIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('verticalTop')}
                >
                  <VerticalAlignTopIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('horizontalCenter')}
                >
                  <AlignHorizontalCenterIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('horizontalLeft')}
                >
                  <AlignHorizontalLeftIcon />
                </Button>
                <Button
                  onClick={() => handleAlignmentOptionSelection('horizontalRight')}
                >
                  <AlignHorizontalRightIcon />
                </Button>
              </ButtonGroup>
            </Grid>
            <SelectAndSaveContainer item>
              <PlaceArtNavigation container>
                <Grid item sx = {{  padding: '0.5rem', paddingLeft: '0' }}>
                  <PlacementBackButton 
                    variant = 'contained' 
                    color   = 'secondary'
                    onClick = {() => handleStepProgression(activeStep - 1)}
                  >
                    <ArrowBackIcon />
                  </PlacementBackButton>
                </Grid>
                <Grid item>
                  { imageProcessing ? 
                    (
                      <CircularLoadingContainer>
                        <CircularProgress 
                          color         = 'secondary' 
                          size          = { '2rem' } 
                          thickness     = { 4 } 
                          sx            = {{  animationDuration: '550ms'  }}
                          disableShrink
                        />
                      </CircularLoadingContainer>
                    ) : (
                      <Grid container>
                        <Button 
                          variant   = 'contained'
                          color     = 'secondary'
                          onClick   = {() => {
                            setImageProcessing(true);
                            handleStepProgression(activeStep + 1);
                          }}
                          sx={{marginRight: '0.5rem'}}
                        >
                          <SecondaryTypography>
                            Skip Logo
                          </SecondaryTypography>
                        </Button>
                        <Button 
                          variant   = 'contained'
                          color     = 'secondary'
                          disabled  = { imageProcessing || !displayedImage  }
                          onClick   = {() => {
                            setImageProcessing(true);
                            handleStepProgression(activeStep + 1);
                          }}
                        >
                          <SecondaryTypography>
                            Save Logo Placement
                          </SecondaryTypography>
                        </Button>
                      </Grid>
                    )
                  }
                </Grid>
              </PlaceArtNavigation>
            </SelectAndSaveContainer>
          </Grid>
        );

      default: return;
    };
  };

  console.log('active step', activeStep)
  return (
    <MainGridContainer container >
      <PromptOptionsContainer item>
        <OptionSelectionContainer>
          <Stepper 
            activeStep        = {activeStep} 
            connector         = {<QontoConnector sx={{color: '#0b779f'}}/>}
            sx                = {{ marginBottom: '1rem' }}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key = {label}>
                <CustomStepLabel StepIconComponent  = {QontoStepIcon}>
                  <SecondaryTypography>
                    {label}
                  </SecondaryTypography>
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
          <OptionMenu
            optionType          = "Products"
            menuIndex           = { 0                      }   
            options             = { getNeededProductData() }
            handleOptionChange  = { handleOptionChange     }
            optionsExpanded     = { activeStep             }
            addMoreCredits      = { addMoreCredits         }
            addRedirect         = { '/'                    }
          />
          <OptionMenu 
            optionType          = "Artwork"
            menuIndex           = { 1                      } 
            options             = { getArtworkUrls()       }
            handleOptionChange  = { handleOptionChange     }
            optionsExpanded     = { activeStep             }
            addMoreCredits      = { addMoreCredits         }
            addRedirect         = { '/design'              }
          />
          <OptionMenu 
            optionType          = "Logos"
            menuIndex           = { 2                   } 
            options             = { getLogoUrls()       }
            handleOptionChange  = { handleOptionChange  }
            optionsExpanded     = { activeStep          }
            addMoreCredits      = { addMoreCredits      }
            addRedirect         = { '/design/add-logo'  }
          />
        </OptionSelectionContainer>
      </PromptOptionsContainer>
      <ImageAreaGrid>
        { handleImageAreaDisplay() }
      </ImageAreaGrid>
      <OrientationContainer item>
        { handleEditOptionsDisplay() }
      </OrientationContainer>
    </MainGridContainer>
  )
}

export default ImagePlacementEditor;