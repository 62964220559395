import { 
  AppBar, 
  Button, 
  Grid, 
  Toolbar, 
  Typography 
} from "@mui/material";
import { TitleTypography } from "./styled";
import { headerLogoSrc } from "./consts";

export default function AppBarMenu({ isMobile, navigate }){
  return (
    <AppBar position="fixed" open={true} sx={{backgroundColor: '#ffffff'}}>
      <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'nowrap',
            width: isMobile ? '100%' : '75%'
          }}
        >
          <Grid item sx={{flex: '1'}}>
            <img height='45rem' src={headerLogoSrc} alt='logo' />
          </Grid>
          {
            !isMobile && (
              <Grid 
                item
                sx={{
                  display: 'flex',
                  flex: '3',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <Button color="secondary" href='#howitworks'>
                  How It Works
                </Button>
                <Button color="secondary" href='#features'> 
                  Features
                </Button>
                <Button color="secondary" href='#process'>
                  Process
                </Button>
              </Grid>
            )
          }
          <Grid item sx={{flex: '1', display: 'flex', justifyContent: isMobile ? 'flex-end' : 'center', alignItems: 'center'}}>
            <Button 
              variant='contained' 
              sx={{backgroundColor: '#3c6efa', color: 'white'}}
              href="#subscribe"
            >
              Free Offer
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}