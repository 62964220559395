import { 
  // Autocomplete,
  CircularProgress, 
  // TextField,
} from '@mui/material';
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { 
  ParentContainer, 
  PromptOptionsContainer 
} from '../CreateArtwork/styled';
import ProductCard from '../../components/ProductCard';
import { 
  addProductToCart, 
  editCartQuantity, 
  removeProductFromCart, 
  updateProductVariant
} from '../../actions/cartActions';
import { currentProducts } from '../../staticData/products';
import { CircularLoadingContainer } from '../ReviewOrder/styled';
import { ProductCardContainer } from './styled';
import { useToast } from '../../libs/toast';
import { storePrintfulProducts } from '../../actions/printfulActions';

function SelectProduct() {

  const dispatch = useDispatch();
  const toast    = useToast()   ;

  const currentCart = useSelector((state) => state?.cartInfo?.cartItems);
  const originalGenImage  = useSelector((state) => (
    state?.stepData?.currentInfo?.artworkProps?.base64Image
  ));
  const productListInfo = useSelector((state) => state?.productInfo?.productsListInfo);
  const productListLoading = useSelector((state) => state?.productInfo?.isLoading);
  
  const [selectedProducts, setSelectedProducts] = useState(null)  ;

  const handleUpdateQuantity = (cartId, newQuantity) => {

    setSelectedProducts(prevProducts => {
      return newQuantity === 0 ?
      prevProducts?.filter(product => product.cartId !== cartId) :
      prevProducts?.map(product =>
        product.cartId === cartId ? { ...product, quantity: newQuantity } : 
        product
      )}
    );

    if(newQuantity === 0){
      dispatch( removeProductFromCart(cartId, dispatch));
    };

    if(newQuantity !== 0){
      dispatch( editCartQuantity(newQuantity, cartId)(dispatch));
    };
  };

  const handleAddProduct = (
    productId, 
    name, 
    quantity,
    mainCategoryId,
    variant,
    price,
    rest
  ) => {
              
    const createCartId = currentCart?.length ? currentCart?.length + 1 : 1;
    const newProduct = {
      cartId            : createCartId     ,
      id                : productId        , 
      name              : name             , 
      quantity          : quantity         ,
      originalGenImage  : originalGenImage ,
      mainCategoryId    : mainCategoryId   ,
      selectedVariant   : variant          ,
      price             : price            ,
      rest              : rest
    };

    if(selectedProducts){
      setSelectedProducts((prev) => [...prev, newProduct]);
    } else {
      setSelectedProducts([newProduct]);
    }

    dispatch( addProductToCart(newProduct, dispatch) );
    toast.success('Product Added to Cart!');
  };

  const handleUpdateProductVariant = (cartId, variantInfo) => {
    setSelectedProducts((prev) => {
      const index = prev.findIndex((product) => product.cartId === cartId);
      if (index >= 0) {
        const updatedProduct = { ...prev[index], selectedVariant: variantInfo };
        return [
          ...prev.slice(0, index),
          updatedProduct,
          ...prev.slice(index + 1)
        ];
      }
      return prev;
    });

    const updatedProductInState = selectedProducts.find((product) => product.cartId === cartId);
    dispatch(updateProductVariant(updatedProductInState, variantInfo));
  };

  useMemo(() => { 
    if(!productListInfo){
      dispatch(storePrintfulProducts(currentProducts, dispatch))
    };

  // eslint-disable-next-line
  }, [productListInfo]);

  const handleLoadingDisplay = () => {
    if(productListLoading){
      return (
        <CircularLoadingContainer>
          <CircularProgress 
            color='secondary' 
            size={'10rem'} 
            thickness={4} 
            disableShrink
            sx={{animationDuration: '550ms'}}
          />
        </CircularLoadingContainer>
      )
    };

    return (
      productListInfo?.map((index) => (
        <ProductCard
          key                        = { index?.product?.id         }
          productSelectionCallback   = { handleAddProduct           }
          quantityChange             = { handleUpdateQuantity       }
          selectedProducts           = { selectedProducts ?? []     }
          productInfo                = { index?.product             }
          variantInfo                = { index?.variants            }
          handleUpdateProductVariant = { handleUpdateProductVariant }
          {...index}
        />
      ))
    );
  };

  return (
    <ParentContainer>
      <PromptOptionsContainer>
        <ProductCardContainer>
          { handleLoadingDisplay() }
        </ProductCardContainer>
      </PromptOptionsContainer>
    </ParentContainer>
  )
}

export default SelectProduct;