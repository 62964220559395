import { Box, Button, Grid, Paper, Typography, styled } from '@mui/material';

const MainPageBox = styled(Box)(() => ({
  flexGrow: 1, 
  display: 'flex', 
  flexDirection: 'column', 
  height: '100dvh', 
  maxHeight: '100%', 
  maxWidth: '-webkit-fill-available' ,
  backgroundColor: 'white'
}));

const SecondaryPageBox = styled(Box)(() => ({
  flex: '1 1 auto', 
  overflow: 'auto', 
  display: 'flex', 
  flexDirection: 'column'
}));

const InnerBox = styled(Box)(({theme}) => ({
  flex: '1 1 auto', 
  display: 'flex',
  alignItems: 'center', 
  justifyContent: 'center',
  height: '25rem',
  width: '10rem',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem'
  }
}));

const InnerVideoBox = styled(Box)(({theme}) => ({
  flex: '1 1 auto', 
  display: 'flex',
  alignItems: 'center', 
  justifyContent: 'center', 
  marginBottom: '1rem', 
  marginTop: '1rem', 
  marginRight: '1rem',
  minHeight: '20rem',
  minWidth: '20rem',
  [theme.breakpoints.down('md')]: {
    minWidth: '10rem',
    minHeight: '10rem'
  }
}));

const HeaderTextGridItem = styled(Grid)(() => ({
  backgroundColor: '#ffffff', 
  boxShadow: '0 0 150px 150px #ffffff', 
  padding: '3rem'
}));

const HeroBox = styled(Box)(({theme}) => ({
  flex: '0.75 1 auto', 
  display: 'flex',
  paddingBottom: '5rem',
  alignItems: 'center', 
  justifyContent: 'center',
  marginBottom: '2rem',
  background: '#ffffff',
  background: 'linear-gradient(0deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.25) 25%, rgba(128,152,238,0.25) 100%)',
  [theme.breakpoints.down('md')]: {
    paddingBottom: '1rem'
  }
}));


const HeroGridContainer = styled(Grid)(({theme}) => ({
  display: 'flex', 
  width: '100%', 
  height: '100%', 
  justifyContent: 'center', 
  alignItems: 'center',
  flexDirection: 'column', 
  marginLeft: '5rem',
  [theme.breakpoints.down('md')]: {
    marginLeft: '1rem',
  }
}));

const HeroGridItem = styled(Grid)(({theme}) => ({
  display: 'flex',
  width: '50%', 
  height: '100%', 
  alignItems: 'flex-start', 
  justifyContent: 'center', 
  flexDirection: 'column', 
  marginLeft: '7rem', 
  zIndex: '5',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    width: '100%',
    justifyContent: 'flex-start',
  }
}));

const HeroHeaderContentText = styled(Typography)(() => ({
  width: '100%', 
  textAlign: 'center', 
  padding: '0.25rem', 
  marginTop: '1rem', 
  marginBottom: '1rem', 
  maxWidth: '40rem'
}));

const VideoBox = styled(Box)(({theme}) => ({
  flex: '1 1 auto', 
  display: 'flex',
  alignItems: 'center', 
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  }
}));

const FeatureBox = styled(Box)(({theme}) => ({
  flex: '1 1 auto', 
  display: 'flex',
  alignItems: 'center', 
  justifyContent: 'center',
  backgroundColor: '#f6f8fa',
  paddingTop: '5rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    paddingTop: '1rem'
  }
}));

const ScreenFeatureBoxInnerGridContainer = styled(Grid)(() => ({
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'center', 
  width: '100%'
}));

const IndividualScreenFeatureGridItem = styled(Grid)(({theme}) => ({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  width: '100%', 
  padding: '5rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '1rem',
    marginBottom: '2rem'
  }
}));

const FeaturePaper = styled(Paper)(({theme}) => ({
  flex: '1', 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '1rem',
  flexWrap: 'wrap',
  paddingTop: '3rem',
  paddingBottom: '3rem',
  borderRadius: '1rem',
  height: '100%',
}));

const FeatureContainerBox = styled(Box)(({theme}) => ({
  flex: '0.5 1 auto', 
  display: 'flex', 
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '5rem',
  backgroundColor: 'rgba(82,115,232, 0.08)',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '1rem'
  }
}))

const HeroTypography = styled(Typography)(({theme}) => ({
  fontWeight: '700', 
  textShadow:'2px 2px 2px #000000',
}));

const HeroSplashTypography = styled(Typography)(({theme}) => ({
  position: 'absolute', 
  fontWeight: '700',
  opacity: '33%', 
  color: 'gray', 
  fontSize: '20rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '10rem',
    left: 0,
    top: 0
  } 
}));

const TitleTypography = styled(Typography)(({theme}) => ({
  marginLeft: '1rem',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0'
  }
}));

const FeatureBoxContentGrid = styled(Grid)(({theme}) => ({
  display: 'flex', 
  justifyContent: 'center', 
  flexDirection: 'column', 
  alignItems: 'flex-start', 
  paddingRight: '1rem', 
  paddingLeft: '1rem',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    height: '100%'
  }
}));

const EmailSubGridContainer = styled(Grid)(({theme}) => ({
  display: 'flex', 
  flexDirection: 'column', 
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '3rem 0'
  }
}));

const EmailSubTextGridItem = styled(Grid)(() => ({
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  flexDirection: 'column'
}));

const EmailSubSubmitButton = styled(Button)(() => ({
  borderRadius: '5rem', 
  width: '10rem', 
  height: '3rem', 
  backgroundColor: '#3c6efa', 
  color: '#ffffff'
}));

const FooterBox = styled(Box)(() => ({
  flex: '1', 
  height: '10rem', 
  width: '100%', 
  backgroundColor: '#1d1f2c'
}));

const FooterContentGridContainer = styled(Grid)(({theme}) => ({
  height: '10rem', 
  display: 'flex', 
  justifyContent: 'space-evenly', 
  alignItems: 'center', 
  flexWrap: 'nowrap', 
  width: '100%',
}));

const FooterLogoGridItem = styled(Grid)(() => ({
  flex: '1', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center'
}));

const FooterLinksGridContainer = styled(Grid)(() => ({
  flex: '2', 
  display: 'flex', 
  justifyContent: 'space-evenly', 
  alignItems: 'center'
}));

const FooterSocialLinksContainer = styled(Grid)(({theme}) => ({
  flex: '1', 
  display: 'flex', 
  justifyContent: 'space-evenly', 
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flex: '0.5'
  }
}));

const ScreenFeatureContentContainer = styled(Grid)(({theme}) => ({
  display: 'flex', 
  justifyContent: 'center', 
  [theme.breakpoints.down('md')]: {
    paddingBottom: '2rem'
  }
}));

const ScreenFeatureContentItem = styled(Grid)(({theme}) => ({
  width: '75%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

const ScreenFeatureHeaderTypography = styled(Typography)(({theme}) => ({
  paddingBottom: '1rem', 
  fontWeight: '700',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center'
  }
}));

export { 
  MainPageBox,
  SecondaryPageBox,
  InnerBox, 
  HeroBox, 
  VideoBox, 
  HeaderTextGridItem,
  HeroHeaderContentText,
  FeaturePaper,
  HeroTypography,
  HeroGridContainer,
  HeroGridItem,
  FeatureContainerBox,
  HeroSplashTypography,
  TitleTypography,
  InnerVideoBox,
  FeatureBoxContentGrid,
  FeatureBox,
  ScreenFeatureBoxInnerGridContainer,
  IndividualScreenFeatureGridItem,
  EmailSubGridContainer,
  EmailSubTextGridItem,
  EmailSubSubmitButton,
  FooterBox,
  FooterContentGridContainer,
  FooterLogoGridItem,
  FooterLinksGridContainer,
  FooterSocialLinksContainer,
  ScreenFeatureContentContainer,
  ScreenFeatureContentItem,
  ScreenFeatureHeaderTypography
}