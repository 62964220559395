import { 
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Outlet 
} from 'react-router-dom';
import { AppContainer } from './styled';
import Profile from './views/Profile';
import Catalog from './views/Catalog';
import Cart from './views/Cart';
import Design from './views/Design';
import Mockup from './views/Mockup';
import Logo from './views/Logo';
import Placement from './views/Placement';
import Checkout from './views/Checkout';
import { useDispatch, useSelector } from 'react-redux';
import AppNavigation from './components/AppNavigation';
import { CssBaseline } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { getUserStripePaymentMethods } from './actions/stripeActions';
import Landing from './views/Landing';
import { upscaleImage } from './helpers/upscaleImage';
import Support from './views/Support';
import YourProducts from './views/YourProducts';
import V2Landing from './views/V2Landing';

function App() {

  const dispatch = useDispatch();
  // const toast = useToast();


  const [authCheckCompleted, setAuthCheckCompleted] = useState(false);

  const stripeCustomerId = useSelector((state) => state?.userData?.loginInfo?.stripeCustomerId);
  const userInfo = useSelector((state) => state?.userData?.loginInfo);

  const PrivateRoutes = () => {
    if(!authCheckCompleted){
      return null;
    };

    return userInfo?.user_id && <Outlet/>;
  };

  useEffect(() => {
    setAuthCheckCompleted(true);
  }, [userInfo?.user_id]);

  useEffect(() => {
    if(stripeCustomerId){
      dispatch(getUserStripePaymentMethods(stripeCustomerId, dispatch))
    }
  }, [stripeCustomerId, dispatch]);

  useMemo(() => {
    if(userInfo?.user_id){
      upscaleImage(userInfo, null, dispatch);
    };
    // eslint-disable-next-line
  }, [userInfo?.user_id]);
  
  return (
    <Router>
      <AppNavigation />
      <AppContainer>
			  <CssBaseline />
        <Routes>
          <Route path = '/'            element = { <Landing   /> } />
          <Route path = "/v2/landing"  element = { <V2Landing /> } />

          <Route element  =  { <PrivateRoutes /> }>

            <Route       path = "/catalog"       element  =  { <Catalog />       } />
            <Route exact path = "/design"        element  =  { <Design />        } />
            <Route exact path = "/mockup"        element  =  { <Mockup />        } />
            <Route exact path = "/cart"          element  =  { <Cart />          } />
            <Route exact path = "/your-products" element  =  { <YourProducts />  } />
            <Route path       = "/checkout"      element  =  { <Checkout />      } />
            <Route path       = "/support"       element  =  { <Support />       } />

            <Route path       = "/profile/:slug"        element = { <Profile />   } />
            <Route exact path = "/design/add-logo"      element = { <Logo />      } />
            <Route path       = "/design/placement/:id" element = { <Placement /> } />            
          </Route>        
        </Routes>
      </AppContainer>
    </Router>
  );
}

export default App;
