export const ARTWORK_GENERATION_REQUEST = 'ARTWORK_GENERATION_REQUEST';
export const ARTWORK_GENERATION_SUCCESS = 'ARTWORK_GENERATION_SUCCESS';
export const ARTWORK_GENERATION_FAIL    = 'ARTWORK_GENERATION_FAIL'   ;

export const IMAGE_DB_SAVE_REQUEST = 'IMAGE_DB_SAVE_REQUEST';
export const IMAGE_DB_SAVE_SUCCESS = 'IMAGE_DB_SAVE_SUCCESS';
export const IMAGE_DB_SAVE_FAIL    = 'IMAGE_DB_SAVE_FAIL'   ;

export const UPSCALE_JOB_CREATE_REQUEST = 'UPSCALE_JOB_CREATE_REQUEST';
export const UPSCALE_JOB_CREATE_SUCCESS = 'UPSCALE_JOB_CREATE_SUCCESS';
export const UPSCALE_JOB_CREATE_FAIL = 'UPSCALE_JOB_CREATE_FAIL';

export const BASE64_IMAGE_UPDATE_SUCCESS = 'BASE64_IMAGE_UPDATE_SUCCESS';
export const LOGO_COMBINED_IMAGE_UPDATE_SUCCESS = 'LOGO_COMBINED_IMAGE_UPDATE_SUCCESS';

export const CHECK_UPSCALE_SUCCESS = 'CHECK_UPSCALE_SUCCESS';
export const CHECK_UPSCALE_ERROR = 'CHECK_UPSCALE_ERROR';
export const CHECK_UPSCALE_PROCESSING = 'CHECK_UPSCALE_PROCESSING';