import axios from 'axios';

export const getDisplayedImageBase64 = async( displayedImage ) => {
  const config = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  };

  return await axios.post(
    `${process.env.REACT_APP_API_PATH}/api/user/hostedImageBase64`,
    { url: displayedImage },
    config
  )
  .then((response) => {
    return response?.data;
  })
  .catch((error) => {
    console.error('Error: ', error.message);
  });
};

export const handleCreateImageFromPlacement = async(
  printAreaRef,
  dragRef,
  resizeRef,
  templateData,
  setArtworkBackground,
  displayedImage,
  setArtworkPlacementDimenstion
) => {
  return new Promise(async (resolve, reject) => {
    const template      = printAreaRef.current;
    const dragElement   = dragRef.current;
    const resizeElement = resizeRef.current;


    console.log('resizeElement first draw', resizeElement);
    console.log('template data', templateData)

    
    if(template && dragElement && resizeElement){
      try {

        const dragRect              = dragElement?.state;
        const resizeRect            = resizeElement?.state;

        const resizeWidth           = resizeRect.width;
        const resizeHeight          = resizeRect.height;
                
        const dragOffsetX           = dragRect.x;
        const dragOffsetY           = dragRect.y;

        const image                 = new Image();
        const dragImage             = new Image();
        
        const dragImageBase64String = await getDisplayedImageBase64(displayedImage);
        const dataURL               = 'data:image/png;base64,' + dragImageBase64String;
        
        // Create new blank image for backdrop;
        const templateCanvas  = document.createElement('canvas');
        templateCanvas.width  = templateData?.template_width    ;
        templateCanvas.height = templateData?.template_height   ;

        const context     = templateCanvas.getContext('2d');
        context.fillStyle = 'transparent'; 
        context.fillRect(0, 0, templateCanvas.width, templateCanvas.height);
        // 

        image.onload = () => {
          const canvas  = document.createElement("canvas");
          canvas.width  = templateData.print_area_width;
          canvas.height = templateData.print_area_height;

          const ctx = canvas.getContext("2d");

          dragImage.onload = () => {

            ctx.drawImage(
              image                , 
              0                    , 
              0                    , 
              template.offsetWidth , 
              template.offsetHeight
            );
    
            ctx.drawImage(
              dragImage   ,
              dragOffsetX ,
              dragOffsetY ,
              resizeWidth ,
              resizeHeight
            );
            
            const imageBase64 = canvas.toDataURL('image/png', 1);
            setArtworkPlacementDimenstion({
              width: template.offsetWidth,
              height: template.offsetHeight,
            });
            setArtworkBackground(imageBase64);
            resolve();
          };

          dragImage.src = dataURL;
        };

        image.src   = templateCanvas.toDataURL(); 
        
      } catch (error) {
        reject(error);
      }
    } else {
      reject("Missing required elements");
    }
  });
};

export const handleCombineBackgroundAndLogo = (
  templateData,
  logoPrintAreaRef,
  dragRef,
  resizeRef,
  artworkBackground,
  displayedImage,
  setArtworkPlacementDimenstion
) => {
  return new Promise(async (resolve, reject) => {
    const template      = logoPrintAreaRef.current  ;
    const dragElement   = dragRef.current           ;
    const resizeElement = resizeRef.current         ;

    console.log('resizeElement', resizeElement);

    if(template && dragElement && resizeElement){

      const dragRect   = dragElement?.state   ;
      const resizeRect = resizeElement?.state ;

      const resizeWidth  = resizeRect.width   ;
      const resizeHeight = resizeRect.height  ;
      
      const dragOffsetX = dragRect.x  ;
      const dragOffsetY = dragRect.y  ;

      const image     = new Image() ;
      const dragImage = new Image() ;

      try {
        const dragImageBase64String = await getDisplayedImageBase64(displayedImage);                   ;
        const dataURL               = 'data:image/png;base64,' + dragImageBase64String  ;

        image.onload = () => {

          const canvas  = document.createElement("canvas");
          canvas.width  = templateData.print_area_width   ;
          canvas.height = templateData.print_area_height  ;
          
          const ctx = canvas.getContext("2d");

          dragImage.onload = () => {

            ctx.drawImage(
              image               ,
              0                   ,
              0                   ,
              template.offsetWidth,
              template.offsetHeight
            );

            ctx.drawImage(
              dragImage   ,
              dragOffsetX ,
              dragOffsetY ,
              resizeWidth ,
              resizeHeight
            );

            const imageBase64 = canvas.toDataURL('image/png', 1)  ;
            setArtworkPlacementDimenstion({
              width: template.offsetWidth,
              height: template.offsetHeight,
            });
            resolve(imageBase64)                                  ;
            return imageBase64                                    ;
          };
          dragImage.onerror = () => {
            reject('failed to load drag image');
          };
          dragImage.src = dataURL;
        };
        image.onerror = () => {
          reject('failed to load image');
        };
        image.src = artworkBackground;
      } catch (error) {
        reject(error);
      }
    } else {
      reject('Template, dragElement, or resizeElement is missing');
    }
  });
};