export const USER_MEMBERSHIP_SAVE_REQUEST = 'USER_MEMBERSHIP_SAVE_REQUEST';
export const USER_MEMBERSHIP_SAVE_SUCCESS = 'USER_MEMBERSHIP_SAVE_SUCCESS';
export const USER_MEMBERSHIP_SAVE_FAIL = 'USER_MEMBERSHIP_SAVE_FAIL';

export const ORDER_SAVE_REQUEST = 'ORDER_SAVE_REQUEST';
export const ORDER_SAVE_SUCCESS = 'ORDER_SAVE_SUCCESS';
export const ORDER_SAVE_FAIL = 'ORDER_SAVE_FAIL';

export const USER_ORDERS_INFO_REQUEST = 'USER_ORDERS_INFO_REQUEST';
export const USER_ORDERS_INFO_SUCCESS = 'USER_ORDERS_INFO_SUCCESS';
export const USER_ORDERS_INFO_FAIL = 'USER_ORDERS_INFO_FAIL';

export const PURCHASE_CREDITS_REQUEST = 'PURCHASE_CREDITS_REQUEST';
export const PURCHASE_CREDITS_SUCCESS = 'PURCHASE_CREDITS_SUCCESS';
export const PURCHASE_CREDITS_FAIL    = 'PURCHASE_CREDITS_FAIL'   ;

export const PAYMENT_SECRET_CLEAR = 'PAYMENT_SECRET_CLEAR';

export const USER_ORDER_PLACED_SUCCESS = 'USER_ORDER_PLACED_SUCCESS';

